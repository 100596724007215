<template>
    <modal ref="YoutubeGuideModal" :useFooter="false" maxWidth="44rem" bodyMaxHeight="none">
        <!-- 가수 -->
        <template v-if="this.genre == 'sing'" slot="body">
            <div class="guide-modal-header">
                <div class="guide-tit">가수, 보컬 분야</div>
                <div class="guide-txt">
                    노래 실력을 확인 할 수 있는<br>
                    영상 최소 5개 이상 등록
                </div>
                <div class="notice">• 행사, 축가 영상 3개 이상 필수</div>
            </div>
            <div class="guide-modal-content">
                <div class="guide-list-wrap">
                    <div class="guide-badge">주의</div>
                    <ul class="guide-list">
                        <li>
                            개인 커버, M/V, 자체 버스킹 등은 행사로 인정되지 않음
                            <ul class="second-list">
                                <li>2분 이상의 영상만 등록 가능</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="guide-list-wrap">
                    <div class="guide-badge">등록불가</div>
                    <ul class="guide-list">
                        <li>
                            노래방, 집 등의 장소에서 촬영한 영상
                            <ul class="second-list">
                                <li>개인 녹음실은 가능</li>
                            </ul>
                        </li>
                        <li>본인 등록 분야와 무관한 영상</li>
                    </ul>
                </div>
            </div>
            <div class="guide-modal-footer">
                <div class="ui-border-line" />
                <button type="button" @click="() => hide()" class="text-center on">
                    <span>닫기</span>
                </button>
            </div>
        </template>
        <!-- // 가수 -->

        <!-- 사회자 -->
        <template v-else-if="this.genre == 'mc'" slot="body">
            <div class="guide-modal-header">
                <div class="guide-tit">사회자 분야</div>
                <div class="guide-txt">
                    진행 실력을 확인할 수 있는 오프라인 행사<br>
                    영상 5개 이상 등록
                </div>
                <div class="notice">• EX : 오프라인 기업행사, 결혼식 등</div>
            </div>
            <div class="guide-modal-content">
                <div class="guide-list-wrap">
                    <div class="guide-badge">주의</div>
                    <ul class="guide-list">
                        <li>3분 이상의 영상만 등록 가능</li>
                        <li>방송/TV 진행 영상은 등록 가능하나, 오프라인 행사로 인정되지 않음</li>
                        <li>동일 행사의 영상을 나눠서 등록할 경우 1개로 인정</li>
                    </ul>
                </div>
                <div class="guide-list-wrap">
                    <div class="guide-badge">등록불가</div>
                    <ul class="guide-list">
                        <li>본인 등록 분야와 무관한 영상</li>
                        <li>실제 진행하는 모습이 1분 이하인 영상</li>
                        <li>1시간 이상의 전체 행사 영상 (하이라이트 편집 필요)</li>
                    </ul>
                </div>
            </div>
            <div class="guide-modal-footer">
                <div class="ui-border-line" />
                <button type="button" @click="() => hide()" class="text-center on">
                    <span>닫기</span>
                </button>
            </div>
        </template>
        <!-- // 사회자 -->

        <!-- 모델 -->
        <template v-else-if="this.genre == 'model'" slot="body">
            <div class="guide-modal-header">
                <div class="guide-tit">모델 분야</div>
                <div class="guide-txt">
                    출연 영상, 스케치 영상, <br>
                    본인 소개 영상 등 (선택사항)
                </div>
            </div>
            <div class="guide-modal-content">
                <div class="guide-list-wrap">
                    <div class="guide-badge">등록불가</div>
                    <ul class="guide-list">
                        <li>본인 등록 분야와 무관한 영상</li>
                    </ul>
                </div>
            </div>
            <div class="guide-modal-footer">
                <div class="ui-border-line" />
                <button type="button" @click="() => hide()" class="text-center on">
                    <span>닫기</span>
                </button>
            </div>
        </template>
        <!-- //모델 -->

        <!-- //연주, 댄스, DJ, 퍼포먼스 -->
        <template v-else-if="this.genre == 'etc'" slot="body">
            <div class="guide-modal-header">
                <div class="guide-tit">연주, 댄스, DJ, 퍼포먼스 분야</div>
                <div class="guide-txt">
                    본인 실력을 확인할 수 있는 오프라인 공연, 행사<br>
                    영상 최소 5개 이상 등록
                </div>
            </div>
            <div class="guide-modal-content">
                <div class="guide-list-wrap">
                    <div class="guide-badge">주의</div>
                    <ul class="guide-list">
                        <li>개인 연습, 커버, M/V 등 등록 가능하나 필수 개수에 반영되지 않음</li>
                        <li>2분 이상의 영상만 등록 가능</li>
                    </ul>
                </div>
                <div class="guide-list-wrap">
                    <div class="guide-badge">등록불가</div>
                    <ul class="guide-list">
                        <li>본인 등록 분야와 무관한 영상</li>
                    </ul>
                </div>
            </div>
            <div class="guide-modal-footer">
                <div class="ui-border-line" />
                <button type="button" @click="() => hide()" class="text-center on">
                    <span>닫기</span>
                </button>
            </div>
        </template>
        <!-- //연주, 댄스, DJ, 퍼포먼스 -->


        <!-- //쇼호스트 -->
        <template v-else-if="this.genre == 'host'" slot="body">
            <div class="guide-modal-header">
                <div class="guide-tit">쇼호스트 분야</div>
                <div class="guide-txt">
                    네이버 쇼핑 라이브 외에 행사 영상 등록
                </div>
            </div>
            <div class="guide-modal-content">
                <div class="guide-list-wrap">
                    <div class="guide-badge">주의</div>
                    <ul class="guide-list">
                        <li>기업행사, 결혼식 등의 활동도 할 경우 5개 이상 필수 등록</li>
                        <li>3분 이상의 영상만 등록 가능</li>
                    </ul>
                </div>
                <div class="guide-list-wrap">
                    <div class="guide-badge">등록불가</div>
                    <ul class="guide-list">
                        <li>본인 등록 분야와 무관한 영상</li>
                        <li>실제 진행하는 모습이 1분 이하인 영상</li>
                        <li>1시간 이상의 전체 행사 영상 (하이라이트 편집 필요)</li>
                    </ul>
                </div>
            </div>
            <div class="guide-modal-footer">
                <div class="ui-border-line" />
                <button type="button" @click="() => hide()" class="text-center on">
                    <span>닫기</span>
                </button>
            </div>
        </template>
        <!-- //쇼호스트 -->
    </modal>
</template>

<script>
import Modal from '@/components/common/Modal'

export default {
    name: 'YoutubeGuideModal',
    components: {
        Modal,
    },
    data() {
        return {
            genre: '' // 동영상 등록 가이드 -> 노출 팝업 구분을 위한 변수 선언
        }
    },
    methods: {
        show(genre) {
            this.$refs.YoutubeGuideModal.show()
            this.genre = genre
        },
        hide() {
            this.$refs.YoutubeGuideModal.hide()
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.guide-modal-header {
    padding: 5rem 0 1.6rem;
    text-align: center;
    .guide-tit {
        font-size: 2.4rem;
        font-weight: 600;
    }
    .guide-txt {
        margin-top: 1rem;
        margin-bottom: 0.6rem;
        font-size: 2rem;
    }
    .notice {
        color: #666;
        font-size: 1.6rem;
    }
}
.guide-modal-content {
    margin: 0 1rem 3rem;
    padding: 2rem;
    border-radius: 0.8rem;
    background-color: #F7F7F7;
    .guide-list-wrap {
        .guide-badge {
            display: inline-block;
            padding: 0.6rem 1rem;
            border-radius: 100rem;
            background-color: rgba(#FF4350,0.1);
            color: #FF4350;
            font-size: 1.4rem;
        }
        .guide-list {
            margin-top: 1.2rem;
            li {
                margin-left: 1.6rem;
                padding: 0 !important;
                line-height: normal !important;
                color: #000 !important;
                font-size: 1.6rem !important;
                list-style: disc;
                letter-spacing: -0.07rem;
                .second-list {
                    margin-top: 0.4rem;
                    li {
                        position: relative;
                        margin-left: 0.9rem !important;
                        color: #999999 !important;
                        list-style: none;
                        &::after {
                            position: absolute;
                            left: -0.9rem;
                            content: '-';
                        }
                    }
                }
                & ~ li {
                    margin-top: 0.8rem;
                }
            }
        }
        & ~ .guide-list-wrap {
            margin-top: 2rem;
            padding-top: 2rem;
            border-top: 1px solid #DDDDDD;
        }
    }
}
.guide-modal-footer {
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 7rem;
        color: $color-deepLavender;
        font-size: 2.2rem;
        span {
            transform: translateY(0.5rem);
        }
    }
}
</style>