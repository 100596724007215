var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-profile-wrap" }, [
    _c("div", { staticClass: "textarea-wrap" }, [
      _c("h4", { style: { fontWeight: 500 } }, [
        _vm._v("활동경력, 전공, 수상내역 등을 상세히 작성"),
        _c("span", { staticClass: "essential" }, [_vm._v("*")])
      ]),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.formData.playDesc,
            expression: "formData.playDesc"
          }
        ],
        attrs: {
          name: "playDesc",
          placeholder:
            "예) OO대학교 OO과 졸업\n2018년 OO대회 우수상 수상\nOO기업 연말 행사 축하공연"
        },
        domProps: { value: _vm.formData.playDesc },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "playDesc", $event.target.value)
            },
            _vm.inputHandler
          ],
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.keyPress($event)
          }
        }
      }),
      _c(
        "span",
        {
          staticClass: "counting",
          class:
            _vm.formData.playDesc && _vm.lengths["playDesc"] >= 50
              ? "check"
              : ""
        },
        [_vm._v("(" + _vm._s(_vm.lengths["playDesc"]) + "/ 최소 50자)")]
      )
    ]),
    _c("div", { staticClass: "textarea-wrap" }, [
      _c("h4", { style: { fontWeight: 500 } }, [
        _vm._v("당신을 섭외해야 하는 이유"),
        _c("br"),
        _vm._v(" 3가지를 알려주세요."),
        _c("span", { staticClass: "essential" }, [_vm._v("*")])
      ]),
      _vm._m(0),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.formData.interview1,
            expression: "formData.interview1"
          }
        ],
        attrs: { name: "interview1" },
        domProps: { value: _vm.formData.interview1 },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "interview1", $event.target.value)
            },
            _vm.inputHandler
          ],
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.keyPress($event)
          }
        }
      }),
      _c(
        "span",
        {
          staticClass: "counting",
          class:
            _vm.formData.interview1 && _vm.lengths["interview1"] >= 30
              ? "check"
              : ""
        },
        [_vm._v("(" + _vm._s(_vm.lengths["interview1"]) + "/ 최소 30자)")]
      )
    ]),
    _c("div", { staticClass: "textarea-wrap" }, [
      _c("h4", { style: { fontWeight: 500 } }, [
        _vm._v("의뢰자, 대중을 만족시키는 "),
        _c("br"),
        _vm._v("본인만의 방법은 무엇인가요?"),
        _c("span", { staticClass: "essential" }, [_vm._v("*")])
      ]),
      _c("div", { staticClass: "input-help" }, [
        _vm._v("노하우 또는 중요시하는 점들을 상세히 기재")
      ]),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.formData.interview2,
            expression: "formData.interview2"
          }
        ],
        attrs: { name: "interview2" },
        domProps: { value: _vm.formData.interview2 },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "interview2", $event.target.value)
            },
            _vm.inputHandler
          ],
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.keyPress($event)
          }
        }
      }),
      _c(
        "span",
        {
          staticClass: "counting",
          class:
            _vm.formData.interview2 && _vm.lengths["interview2"] >= 50
              ? "check"
              : ""
        },
        [_vm._v("(" + _vm._s(_vm.lengths["interview2"]) + "/ 최소 50자)")]
      )
    ]),
    _c("div", { staticClass: "textarea-wrap" }, [
      _c("h4", { style: { fontWeight: 500 } }, [
        _vm._v("의뢰자에게 어떤 전문가로 "),
        _c("br"),
        _vm._v("기억되고 싶으세요?"),
        _c("span", { staticClass: "essential" }, [_vm._v("*")])
      ]),
      _c("div", { staticClass: "input-help" }, [
        _vm._v("기억 또는 비춰지길 바라는 모습 등")
      ]),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.formData.interview3,
            expression: "formData.interview3"
          }
        ],
        attrs: { name: "interview3" },
        domProps: { value: _vm.formData.interview3 },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "interview3", $event.target.value)
            },
            _vm.inputHandler
          ],
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.keyPress($event)
          }
        }
      }),
      _c(
        "span",
        {
          staticClass: "counting",
          class:
            _vm.formData.interview3 && _vm.lengths["interview3"] >= 30
              ? "check"
              : ""
        },
        [_vm._v("(" + _vm._s(_vm.lengths["interview3"]) + "/ 최소 30자)")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-help" }, [
      _vm._v("본인만의 장점이나 매력, 필요성 등을 어필하세요."),
      _c("br"),
      _vm._v(" * 상세할수록 어필이 잘 돼요!")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }