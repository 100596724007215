var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", { ref: "ArtistGuideModal", attrs: { useFooter: false } }, [
    _c("ul", { attrs: { slot: "body" }, slot: "body" }, [
      _c(
        "li",
        {
          staticClass: "text-center",
          on: {
            click: function() {
              _vm.hide()
              this$1.$emit("guide", "sing")
            }
          }
        },
        [
          _vm._v(" 가수, 보컬 분야 "),
          _c("div", { staticClass: "ui-border-line" })
        ]
      ),
      _c(
        "li",
        {
          staticClass: "text-center",
          on: {
            click: function() {
              _vm.hide()
              this$1.$emit("guide", "mc")
            }
          }
        },
        [
          _vm._v(" 사회자, 아나운서 분야 "),
          _c("div", { staticClass: "ui-border-line" })
        ]
      ),
      _c(
        "li",
        {
          staticClass: "text-center",
          on: {
            click: function() {
              _vm.hide()
              this$1.$emit("guide", "host")
            }
          }
        },
        [
          _vm._v(" 쇼호스트 분야 "),
          _c("div", { staticClass: "ui-border-line" })
        ]
      ),
      _c(
        "li",
        {
          staticClass: "text-center",
          on: {
            click: function() {
              _vm.hide()
              this$1.$emit("guide", "etc")
            }
          }
        },
        [
          _vm._v(" 연주, 댄스, DJ, 퍼포먼스 분야 "),
          _c("div", { staticClass: "ui-border-line" })
        ]
      ),
      _c(
        "li",
        {
          staticClass: "text-center",
          on: {
            click: function() {
              _vm.hide()
              this$1.$emit("guide", "model")
            }
          }
        },
        [_vm._v(" 모델 분야 "), _c("div", { staticClass: "ui-border-line" })]
      ),
      _c(
        "li",
        {
          staticClass: "text-center on",
          on: {
            click: function() {
              return _vm.hide()
            }
          }
        },
        [_vm._v(" 닫기 ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }