var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-profile-wrap" },
    [
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-2" }),
      _c(
        "div",
        { staticClass: "form-wrap" },
        [
          _c("h4", { style: { fontWeight: 500 } }, [
            _vm._v("전문분야"),
            _c("span", { staticClass: "essential" }, [_vm._v("*")])
          ]),
          _vm._m(0),
          _c(
            "tag-box",
            [
              _vm._l(_vm.genres, function(row, key) {
                return _c(
                  "li",
                  {
                    key: key,
                    class: {
                      on: !!_vm.formData.genre && _vm.formData.genre == row
                    },
                    on: {
                      click: function() {
                        _vm.setGenre(row)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$lib.t_genre(row)) + " ")]
                )
              }),
              _c(
                "li",
                {
                  class: {
                    on: _vm.isEtc
                  },
                  on: {
                    click: function() {
                      return _vm.$refs.etcModal.show()
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        this.etcData.genreEtc
                          ? this.etcData.genreEtc
                          : "직접입력"
                      ) +
                      " "
                  )
                ]
              )
            ],
            2
          )
        ],
        1
      ),
      _vm.formData.genre == "MC"
        ? _c(
            "div",
            { staticClass: "form-wrap" },
            [
              _c("div", { staticClass: "profile-tooltip" }, [
                _c(
                  "h4",
                  { style: { fontWeight: 500 } },
                  [
                    _vm._v(" 그룹 선택 (복수 선택) "),
                    _c("span", { staticClass: "essential" }, [_vm._v("*")]),
                    _c("icon", {
                      staticClass: "profile-tooltip-btn ui-btn",
                      attrs: {
                        src: "img/common/tooltip.png",
                        width: "2.2rem",
                        height: "2.2rem",
                        cover: false
                      },
                      on: {
                        click: function() {
                          return _vm.showTooltip("profile")
                        }
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "profile-tooltip-toast toasted primary default",
                        class: { show: _vm.tooltip.profile }
                      },
                      [
                        _vm._v(
                          " 사회자 분야에서 어느 직군에 속해있는지, 전문분야가 무엇인지 보여줄 수 있어 의뢰자 분들이 전문가님을 더욱 쉽고 빠르게 찾을 수 있어요. "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "tag-box",
                [
                  _vm._l(_vm.genreTypes, function(row, key) {
                    return _c(
                      "li",
                      {
                        key: key,
                        class: { on: _vm.inputData.genreType[row] },
                        on: {
                          click: function() {
                            _vm.setGenreType(row)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(row) + " ")]
                    )
                  }),
                  _c(
                    "li",
                    {
                      staticClass: "ui-btn",
                      on: {
                        click: function() {
                          return _vm.$refs.etcMcGroupModal.show()
                        }
                      }
                    },
                    [_vm._v(" 직접입력 ")]
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.formData.genre == "쇼호스트"
        ? _c(
            "div",
            { staticClass: "form-wrap" },
            [
              _c("h4", { style: { fontWeight: 500 } }, [
                _vm._v("진행 가능한 쇼호스트 분야 (복수 선택)"),
                _c("span", { staticClass: "essential" }, [_vm._v("*")])
              ]),
              _c(
                "tag-box",
                _vm._l(
                  this.labels.filter(function(row) {
                    return row != "연예인"
                  }),
                  function(row, key) {
                    return _c(
                      "li",
                      {
                        key: key,
                        class: { on: _vm.inputData.showHost[key] },
                        on: {
                          click: function() {
                            _vm.setShowhost(key)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(row) + " ")]
                    )
                  }
                ),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _vm.subGenreList && _vm.subGenreList.length > 0
        ? _c(
            "div",
            { staticClass: "form-wrap" },
            [
              _c("h4", { style: { fontWeight: 500 } }, [
                _vm._v("자신 있는 장르 (복수 선택)"),
                _c("span", { staticClass: "essential" }, [_vm._v("*")])
              ]),
              _c(
                "tag-box",
                [
                  _vm._l(
                    _vm.subGenreList.filter(function(row) {
                      return row != "기타"
                    }),
                    function(row, key) {
                      return _c(
                        "li",
                        {
                          key: key,
                          class: {
                            on: _vm.formData.subGenre
                              ? _vm.formData.subGenre.split(",").includes(row)
                              : null
                          },
                          on: {
                            click: function() {
                              _vm.setSubGenreText(row, "default")
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(row) + " ")]
                      )
                    }
                  ),
                  _c(
                    "li",
                    {
                      class: {
                        on: _vm.isEtc
                      },
                      on: {
                        click: function() {
                          return _vm.$refs.etcSubGenreModal.show()
                        }
                      }
                    },
                    [_vm._v(" 직접입력 ")]
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "form-wrap" },
        [
          _c("h4", { style: { fontWeight: 500 } }, [
            _vm._v("경력"),
            _c("span", { staticClass: "essential" }, [_vm._v("*")])
          ]),
          _c("check-box", {
            attrs: {
              text:
                _vm.formData.career > 0
                  ? _vm.$config.constant.career[_vm.formData.career]
                  : "해당 분야의 경력을 선택해주세요.",
              click: function() {
                _vm.modalData.career = _vm.formData.career
                _vm.$refs.CareerModal.show()
              },
              on: _vm.formData.career > 0,
              iconPos: "right",
              select: ""
            }
          }),
          _c(
            "modal",
            {
              ref: "CareerModal",
              attrs: {
                title: "경력을 선택해주세요.",
                confirmCb: function(modal) {
                  _vm.formData.career = _vm.modalData.career
                  modal.hide()
                },
                hiddenCb: function() {}
              }
            },
            [
              _c(
                "ul",
                { attrs: { slot: "body" }, slot: "body" },
                _vm._l(_vm.$config.constant.career, function(row, key) {
                  return _c(
                    "li",
                    {
                      key: key,
                      staticClass: "text-center",
                      class: { on: _vm.modalData.career === Number(key) },
                      on: {
                        click: function() {
                          return (_vm.modalData.career = Number(key))
                        }
                      }
                    },
                    [
                      key > 0
                        ? [
                            _vm._v(" " + _vm._s(row) + " "),
                            _c("div", { staticClass: "ui-border-line" })
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                }),
                0
              )
            ]
          )
        ],
        1
      ),
      _vm.formData.genre == "노래"
        ? _c(
            "div",
            { staticClass: "form-wrap" },
            [
              _c("h4", { style: { fontWeight: 500 } }, [
                _vm._v("팀 종류 (복수 선택)"),
                _c("span", { staticClass: "essential" }, [_vm._v("*")])
              ]),
              _c(
                "tag-box",
                _vm._l(this.$config.constant.teamType, function(row, key) {
                  return _c(
                    "li",
                    {
                      key: key,
                      class: { on: _vm.inputData.teamType.indexOf(key) !== -1 },
                      on: {
                        click: function() {
                          var idx = _vm.inputData.teamType.indexOf(key)
                          if (idx === -1) {
                            _vm.inputData.teamType.push(key)
                          } else {
                            _vm.inputData.teamType.splice(idx, 1)
                          }
                          _vm.formData.teamType = _vm.inputData.teamType
                            .map(function(type) {
                              return type.trim()
                            })
                            .join(",")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(row) + " ")]
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _vm.formData.genre == "노래"
        ? _c("div", { staticClass: "form-wrap" }, [
            _c("h4", { style: { fontWeight: 500 } }, [
              _vm._v("자신 있는 노래"),
              _c("span", { staticClass: "essential" }, [_vm._v("*")])
            ]),
            _c("div", { staticClass: "input-help" }, [
              _vm._v("가수명-노래제목의 형식으로 등록해주세요.")
            ]),
            _c("div", { staticClass: "ui-inputbox has-btn" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.song,
                    expression: "song"
                  }
                ],
                attrs: {
                  type: "text",
                  placeholder: "예) 아이유-금요일에 만나요"
                },
                domProps: { value: _vm.song },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.song = $event.target.value
                  }
                }
              }),
              _c(
                "button",
                {
                  staticClass: "add-btn",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.addRecommSong()
                    }
                  }
                },
                [_vm._v(" 추가 ")]
              )
            ]),
            _vm.inputData.recommSong && _vm.inputData.recommSong != ""
              ? _c(
                  "ul",
                  { staticClass: "added-list" },
                  _vm._l(_vm.inputData.recommSong, function(row, key) {
                    return _c("div", { key: key }, [
                      _c("li", [
                        _c("span", [_vm._v(_vm._s(row))]),
                        _c(
                          "div",
                          {
                            staticClass: "ui-btn",
                            on: {
                              click: function($event) {
                                return _vm.deleteData("recommSong", key)
                              }
                            }
                          },
                          [
                            _c("icon", {
                              attrs: {
                                src: "img/common/close-gray-button.png",
                                width: "1.6rem",
                                height: "1.6rem",
                                cover: false
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  }),
                  0
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.formData.genre == "노래"
        ? _c("div", { staticClass: "form-wrap" }, [
            _c("h4", { style: { fontWeight: 500 } }, [
              _vm._v("닮은 목소리의 연예인 이름"),
              _c("span", { staticClass: "essential" }, [_vm._v("*")])
            ]),
            _c("div", { staticClass: "ui-inputbox has-btn" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.voice,
                    expression: "voice"
                  }
                ],
                attrs: { type: "text", placeholder: "태연, 아리아나 그란데" },
                domProps: { value: _vm.voice },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.voice = $event.target.value
                  }
                }
              }),
              _c(
                "button",
                {
                  staticClass: "add-btn",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.addMatchVoice()
                    }
                  }
                },
                [_vm._v(" 추가 ")]
              )
            ]),
            _vm.inputData.matchVoice && _vm.inputData.matchVoice != ""
              ? _c(
                  "ul",
                  { staticClass: "added-list" },
                  _vm._l(_vm.inputData.matchVoice, function(row, key) {
                    return _c("div", { key: key }, [
                      _c("li", [
                        _c("span", [_vm._v(_vm._s(row))]),
                        _c(
                          "div",
                          {
                            staticClass: "ui-btn",
                            on: {
                              click: function($event) {
                                return _vm.deleteData("matchVoice", key)
                              }
                            }
                          },
                          [
                            _c("icon", {
                              attrs: {
                                src: "img/common/close-gray-button.png",
                                width: "1.6rem",
                                height: "1.6rem",
                                cover: false
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  }),
                  0
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.formData.genre == "MC"
        ? _c(
            "div",
            { staticClass: "form-wrap" },
            [
              _c("h4", { style: { fontWeight: 500 } }, [
                _vm._v("진행 스타일 (복수 선택)"),
                _c("span", { staticClass: "essential" }, [_vm._v("*")])
              ]),
              _c(
                "tag-box",
                _vm._l(this.$config.constant.mcStyle, function(row, key) {
                  return _c(
                    "li",
                    {
                      key: key,
                      class: { on: _vm.inputData.mcStyle.indexOf(row) !== -1 },
                      on: {
                        click: function() {
                          var idx = _vm.inputData.mcStyle.indexOf(row)
                          if (idx === -1) {
                            _vm.inputData.mcStyle.push(row)
                          } else {
                            _vm.inputData.mcStyle.splice(idx, 1)
                          }
                          _vm.formData.mcStyle =
                            _vm.inputData.mcStyle.join("|") || null
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(row) + " ")]
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _vm.formData.genre == "모델"
        ? _c("div", [
            _c("div", { staticClass: "form-wrap" }, [
              _c("h4", { style: { fontWeight: 500 } }, [
                _vm._v("키"),
                _c("span", { staticClass: "essential" }, [_vm._v("*")])
              ]),
              _c("div", { staticClass: "ui-inputbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.bodyHeight,
                      expression: "formData.bodyHeight"
                    }
                  ],
                  attrs: {
                    type: "tel",
                    pattern: "[0-9]*",
                    inputmode: "numeric",
                    placeholder: "예) 177"
                  },
                  domProps: { value: _vm.formData.bodyHeight },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formData,
                          "bodyHeight",
                          $event.target.value
                        )
                      },
                      function($event) {
                        return _vm.onInputCheck($event, "bodyHeight")
                      }
                    ]
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-wrap" }, [
              _c("h4", { style: { fontWeight: 500 } }, [
                _vm._v("몸무게"),
                _c("span", { staticClass: "essential" }, [_vm._v("*")])
              ]),
              _c("div", { staticClass: "ui-inputbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.bodyWeight,
                      expression: "formData.bodyWeight"
                    }
                  ],
                  attrs: {
                    type: "tel",
                    pattern: "[0-9]*",
                    inputmode: "numeric",
                    placeholder: "예) 55"
                  },
                  domProps: { value: _vm.formData.bodyWeight },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formData,
                          "bodyWeight",
                          $event.target.value
                        )
                      },
                      function($event) {
                        return _vm.onInputCheck($event, "bodyWeight")
                      }
                    ]
                  }
                })
              ])
            ]),
            _c(
              "div",
              { staticClass: "form-wrap" },
              [
                _c("h4", { style: { fontWeight: 500 } }, [
                  _vm._v("상의 사이즈"),
                  _c("span", { staticClass: "essential" }, [_vm._v("*")])
                ]),
                _c(
                  "tag-box",
                  _vm._l(this.$config.constant.bodyTopsize, function(row, key) {
                    return _c(
                      "li",
                      {
                        key: key,
                        class: { on: _vm.formData.bodyTopsize === key },
                        on: {
                          click: function() {
                            _vm.formData.bodyTopsize = key
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(row) + " ")]
                    )
                  }),
                  0
                )
              ],
              1
            ),
            _c("div", { staticClass: "form-wrap" }, [
              _c("h4", { style: { fontWeight: 500 } }, [
                _vm._v("하의 사이즈"),
                _c("span", { staticClass: "essential" }, [_vm._v("*")])
              ]),
              _c("div", { staticClass: "ui-inputbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.bodyBottomsize,
                      expression: "formData.bodyBottomsize"
                    }
                  ],
                  attrs: {
                    type: "tel",
                    pattern: "[0-9]*",
                    inputmode: "numeric",
                    placeholder: "예) 29"
                  },
                  domProps: { value: _vm.formData.bodyBottomsize },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formData,
                          "bodyBottomsize",
                          $event.target.value
                        )
                      },
                      function($event) {
                        return _vm.onInputCheck($event, "bodyBottomsize")
                      }
                    ]
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-wrap" }, [
              _c("h4", { style: { fontWeight: 500 } }, [
                _vm._v("신발 사이즈"),
                _c("span", { staticClass: "essential" }, [_vm._v("*")])
              ]),
              _c("div", { staticClass: "ui-inputbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.bodyShoesize,
                      expression: "formData.bodyShoesize"
                    }
                  ],
                  attrs: {
                    type: "tel",
                    pattern: "[0-9]*",
                    inputmode: "numeric",
                    placeholder: "예) 200"
                  },
                  domProps: { value: _vm.formData.bodyShoesize },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formData,
                          "bodyShoesize",
                          $event.target.value
                        )
                      },
                      function($event) {
                        return _vm.onInputCheck($event, "bodyShoesize")
                      }
                    ]
                  }
                })
              ])
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "form-wrap" }, [
        _c("h4", { style: { fontWeight: 500 } }, [
          _vm._v("출연했던 TV프로그램")
        ]),
        _c("div", { staticClass: "ui-inputbox has-btn" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.program,
                expression: "program"
              }
            ],
            attrs: { type: "text", placeholder: "출연 프로그램명" },
            domProps: { value: _vm.program },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.program = $event.target.value
              }
            }
          }),
          _c(
            "button",
            {
              staticClass: "add-btn",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.addProgram()
                }
              }
            },
            [_vm._v(" 추가 ")]
          )
        ]),
        _vm.inputData.program && _vm.inputData.program != ""
          ? _c(
              "ul",
              { staticClass: "added-list" },
              _vm._l(_vm.inputData.program, function(row, key) {
                return _c("div", { key: key }, [
                  _c("li", [
                    _c("span", [_vm._v(_vm._s(row))]),
                    _c(
                      "div",
                      {
                        staticClass: "ui-btn",
                        on: {
                          click: function($event) {
                            return _vm.deleteData("program", key)
                          }
                        }
                      },
                      [
                        _c("icon", {
                          attrs: {
                            src: "img/common/close-gray-button.png",
                            width: "1.6rem",
                            height: "1.6rem",
                            cover: false
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              }),
              0
            )
          : _vm._e()
      ]),
      _c("div", { staticClass: "form-wrap" }, [
        _c("h4", { style: { fontWeight: 500 } }, [
          _vm._v("나의 매력 태그"),
          _c("span", { staticClass: "essential" }, [_vm._v("*")])
        ]),
        _c("div", { staticClass: "input-help" }, [
          _vm._v(
            "장점 성격, 출연 방송 등 자랑하고 싶은 점을 태그로 표현하세요 너무 많으면 안보이니 미리보기로 확인하세요."
          )
        ]),
        _c("div", { staticClass: "ui-inputbox typing-count has-btn" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.hashTag,
                expression: "hashTag"
              }
            ],
            attrs: {
              type: "text",
              placeholder: "태그를 입력해주세요",
              maxlength: "10"
            },
            domProps: { value: _vm.hashTag },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.hashTag = $event.target.value
                },
                _vm.inputTagHandler
              ],
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.keyPress($event)
              }
            }
          }),
          _c("span", { staticClass: "counting" }, [
            _vm._v("(" + _vm._s(_vm.currentLength) + "/10)")
          ]),
          _c(
            "button",
            {
              staticClass: "add-btn",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.addHashTag()
                }
              }
            },
            [_vm._v(" 추가 ")]
          )
        ]),
        _vm.inputData.hashTag && _vm.inputData.hashTag != ""
          ? _c(
              "ul",
              { staticClass: "added-list" },
              _vm._l(_vm.inputData.hashTag, function(row, key) {
                return _c("div", { key: key }, [
                  _c("li", [
                    _c("span", [_vm._v(_vm._s(row))]),
                    _c(
                      "div",
                      {
                        staticClass: "ui-btn",
                        on: {
                          click: function($event) {
                            return _vm.deleteData("hashTag", key)
                          }
                        }
                      },
                      [
                        _c("icon", {
                          attrs: {
                            src: "img/common/close-gray-button.png",
                            width: "1.6rem",
                            height: "1.6rem",
                            cover: false
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              }),
              0
            )
          : _vm._e()
      ]),
      _c(
        "modal",
        {
          ref: "etcModal",
          staticClass: "etc",
          attrs: {
            title: "직접 입력해주세요",
            showCb: function() {
              return (_vm.etcData.input = _vm.etcData.genreEtc)
            },
            confirm: "등록",
            confirmCb: function(modal) {
              _vm.setGenreFromEtc(function() {
                modal.hide()
              })
            }
          }
        },
        [
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c("div", { staticClass: "ui-inputbox etc" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.etcData.input,
                    expression: "etcData.input"
                  }
                ],
                attrs: { type: "text", placeholder: "기타" },
                domProps: { value: _vm.etcData.input },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.etcData, "input", $event.target.value)
                    },
                    _vm.validateInput
                  ]
                }
              })
            ])
          ])
        ]
      ),
      _c(
        "modal",
        {
          ref: "etcSubGenreModal",
          staticClass: "etcSubGenre",
          attrs: {
            title: "직접 입력해주세요",
            showCb: function() {
              return (_vm.etcData.input = "")
            },
            confirm: "등록",
            confirmCb: function(modal) {
              _vm.setSubGenreFromEtc(function() {
                modal.hide()
              })
            }
          }
        },
        [
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c("div", { staticClass: "ui-inputbox etc" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.etcData.input,
                    expression: "etcData.input"
                  }
                ],
                attrs: { type: "text", placeholder: "기타" },
                domProps: { value: _vm.etcData.input },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.etcData, "input", $event.target.value)
                  }
                }
              })
            ])
          ])
        ]
      ),
      _c(
        "modal",
        {
          ref: "etcMcGroupModal",
          staticClass: "etcMC",
          attrs: {
            title: "직접 입력해주세요",
            showCb: function() {
              return (_vm.etcData.genreType = "")
            },
            confirm: "등록",
            confirmCb: function(modal) {
              if (!_vm.etcData.genreType) {
                return
              }

              _vm.genreTypes.push(_vm.etcData.genreType)
              _vm.$set(_vm.inputData.genreType, _vm.etcData.genreType, false)
              _vm.setGenreType(_vm.etcData.genreType)
              modal.hide()
            }
          }
        },
        [
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c("div", { staticClass: "ui-inputbox etc" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.etcData.genreType,
                    expression: "etcData.genreType"
                  }
                ],
                attrs: { type: "text", placeholder: "기타" },
                domProps: { value: _vm.etcData.genreType },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.etcData, "genreType", $event.target.value)
                  }
                }
              })
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "guideLetter" }, [
      _vm._v(" 선택 Tip"),
      _c("br"),
      _vm._v(" 마술, 퓨전국악, 뮤지컬 등은 ‘퍼포먼스'를 선택 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }