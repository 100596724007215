<template>
  <div class="my-profile-wrap">
    <page-header
      :title="`전문성과 실력을 보일 수 있는\n영상을 등록해주세요. `"
      :title-small="`(${formData.movData.length}/20)`"
    >
      <template slot="subtitle">
        등록 가이드에 맞는 영상을 등록하세요. <span class="red-font">(최소 5개)</span><br>
        <span class="grey-font">(영상 추가 후, 반드시 미리보기를 진행해주세요!)</span>
      </template>
    </page-header>

    <button type="button" class="guide-btn" @click="() => $refs.ArtistGuideModal.show()"> 등록 가이드 보기 ></button>

    <div class="media-list">
      <div v-if="formData.movData.length < 20" class="media-item media-add-btn">
        <div class="ui-inputbox ui-btn btn-add" @click="() => openYoutubeModal()">
          <icon src="img/common/plus-gray.png" width="2.4rem" height="2.4rem" :cover="false" />
        </div>
      </div>
      <div v-for="(row, key) in formData.movData" :key="key"
        class="media-item">
        <div class="ui-inputbox">
          <youtube
              v-if="$youtube.getIdFromUrl(row.movUrl) !== null"
              :video-id="$youtube.getIdFromUrl(row.movUrl)"
              :player-vars="{ rel: 0, showinfo: 0 }"
              :style="{
                width: '360px',
                height: `140px`,
              }"
          />
          <!-- <div class="youtube-preview ui-btn" @click="() => $gotoWeb(row.movUrl)">
            <div class="youtube-preview-cover" />
            <div class="youtube-preview-player">
              <div
                :style="{
                  backgroundImage: `url(https://img.youtube.com/vi/${$youtube.getIdFromUrl(row.movUrl)}/mqdefault.jpg)`,
                  width: '284px',
                  height: '160px',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }"
              />
            </div>
          </div> -->

          <button type="button" class="item-delete" @click="() => deleteData(key)">
            <icon src="img/common/close.png" width="2.8rem" height="2.8rem" :cover="false" />
          </button>

          <div class="item-handler" @click="() => openManageModal(key)">
            <icon src="img/my-profile/edit.png" width="3.8rem" height="3.8rem" :cover="false" />
          </div>
        </div>
      </div>
    </div>
    <page-modal
      ref="pageModal"
      :title="'없으면 손해 보는 필수 영상!'"
      :subtitle="'의뢰인들은 아래의 영상이 있는 분들을 격하게 선호해요.'"
      >
      <div slot="body" class="page-modal-body">
        <img
          slot="body"
          :src="$lib.imgUrl('img/my-profile/my-profile-movie-information.png', true)"
          alt="image"
          :style="{
            margin: 'auto',
            display: 'block',
            padding: '10% 10% 20%',
          }"
          >
      </div>
    </page-modal>

    <modal
      ref="youtubeModal"
      title="유튜브 URL 등록"
      confirm="등록"
      :confirmCb="confirmYoutubeModal"
      :hiddenCb="clearYoutubeModal"
      :useCancel="false"
      noBorder
      roundModal
    >
      <template slot="body">
        <input
          v-model="youtubeModal.url"
          type="text"
          placeholder="url을 입력하세요"
        />
        <p class="input-notice"  v-if="!isValidUrl" >※ 유튜브 URL만 등록 가능합니다.</p>
      </template>
    </modal>

    <modal
      ref="manageModal"
      :hidden-cb="clearManageModal"
      :use-footer="false"
      :roundModal="true"
    >
      <ul slot="body">
        <li
          class="text-center"
          @click="() => {
            openYoutubeModal(manageModal.index)
            $refs.manageModal.hide()
          }"
        >
          링크 수정
          <div class="ui-border-line"></div>
        </li>
        <li
          class="text-center"
          @click="() => {
            formData.movData.splice(manageModal.index, 1)
            $refs.manageModal.hide()
          }"
        >
          영상 삭제
          <div class="ui-border-line"></div>
        </li>
        <li class="text-center on" @click="() => $refs.manageModal.hide()">취소</li>
      </ul>
    </modal>

    <ArtistGuideModal ref="ArtistGuideModal" @guide="(genre) => $refs.YoutubeGuideModal.show(genre)"/>
    <YoutubeGuideModal ref="YoutubeGuideModal" />
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import Icon from '@/components/common/Icon'
import TagBox from '@/components/common/TagBox'
import CheckBox from '@/components/common/CheckBox'
import Modal from '@/components/common/Modal'
import PageModal from '@/components/common/PageModal'
import ArtistGuideModal from '@/components/modal/ArtistGuideModal.vue'
import YoutubeGuideModal from '@/components/modal/YoutubeGuideModal.vue'
import router from '@/router'

export default {
  components: {
    PageHeader,
    Icon,
    TagBox,
    CheckBox,
    Modal,
    PageModal,
    ArtistGuideModal,
    YoutubeGuideModal,
  },
  props: {
    artistData: {
      type: Object,
      required: true,
    },
  },
  data() {
    const artistData = this.artistData
    let movData = [];

    if (Array.isArray(artistData.movData) && artistData.movData.length > 0) {
      artistData.movData.forEach(row => {
        if (row.movUrl && this.$youtube.getIdFromUrl(row.movUrl)) {
          movData.push({
            mode: 'youtube',
            movUrl: row.movUrl,
          })
        }
      })
    }
    const width = 284 / 10

    return {
      youtube: {
        width,
        height: this.$lib.getRatioHeight(16/9, width),
        isHide: false,
        limit: 2,
      },

      formData: {
        movData,
      },
      youtubeModal: {
        index: -1,
        url: '',
      },
      manageModal: {
        index: -1,
      },
      validate: {
        nav : 2,
        sub : 2,
        isValid: false,
      },
      isValidUrl: true,
    }
  },
  watch: {
    formData: {
      handler(newValue, oldValue) {
        this.checkValid()
        // 전체 formData를 감시하여 특정 로직을 수행합니다.
        let formData = this.setFormData()
        this.$emit('update-data', formData)
      },
      deep: true // 객체 내부의 속성까지 감시
    }
  },
  created() {
    this.checkValid()
  },
  beforeRouteEnter(to, from, next) {
    next(Vue => {
      window.injectArtistMovUrl = Vue.injectArtistMovUrl
    })
  },
  beforeRouteLeave(to, from, next) {
    window.injectArtistMovUrl = () => false
    next()
  },
  methods: {
    setFormData() {
      let formData = {}
      for (let key = 0; key < 20; key++) {
        let val = this.formData.movData[key]
        if (val) {
          val = JSON.parse(JSON.stringify(val))
          formData['movUrl' + (key + 1)] = val.movUrl
        } else {
          formData['movUrl' + (key + 1)] = ''
        }
      }
      return formData
    },
    injectArtistMovUrl(url) {
      if (url) {
        this.formData.movData.push({
          mode: 'upload',
          movUrl: url,
        })
        this.$forceUpdate()
        this.$toast('업로드 완료. 곧 등록될 거예요!')
      } else {
        this.$toast('동영상 업로드에 실패하였습니다.')
      }
    },
    tempUpload(files) {
      if (!files.length) return

      if (files[0].size > 314572800) {
        this.$toast('영상의 용량이 커서 업로드 할 수 없습니다.(최대 300MB)')
        return
      }

      const data = new FormData()
      data.append('uploadFIle', files)

      const req = {
        method: 'post',
        url: `/etc/upload`,
        data,
      }

      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.injectArtistMovUrl(data.response.uploadUrl)
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    openYoutubeModal(index = -1) {
      let nextUrl = ''

      if (index !== -1) {
        const movData = this.formData.movData[index]

        if (!movData || movData?.mode === 'upload') {
          index = -1
        } else {
          nextUrl = movData?.movUrl || ''
        }
      }

      this.youtubeModal.index = index
      this.youtubeModal.url = nextUrl
      this.$refs.youtubeModal.show()
    },
    deleteData(key){
      this.formData.movData.splice(key, 1)
    },
    confirmYoutubeModal(modal) {
      const { url, index } = this.youtubeModal

      if (/(shorts)/.test(url)) {
        this.$toast('shorts가 포함된 url은 등록할 수 없어요.')
        return
      }

      if (!this.$lib.isAllowMovUrl(url)) {
        // this.$toast('유튜브 URL을 입력해주세요.')
        this.isValidUrl = false;
        return
      }else{
        this.isValidUrl = true;
      }
      
      // 최대 등록개수가 넘으면 저장하지 않는다
      if(this.formData.movData.length > 19){
        return
      }

      if (index === -1) {
        this.formData.movData.push({
          mode: 'youtube',
          movUrl: url,
        })
      } else {
        this.formData.movData[index].movUrl = url
      }

      modal.hide()
    },
    clearYoutubeModal() {
      this.youtubeModal.index = -1
      this.youtubeModal.url = ''
      this.isValidUrl = true;
    },
    openManageModal(index = -1) {
      this.manageModal.index = index
      this.$refs.manageModal.show()
    },
    clearManageModal() {
      this.manageModal.index = -1
    },
    checkValid() {
      let isValid = this.formData.movData.filter(row => !!row.movUrl).length >= 5
      this.validate.isValid = isValid;

      this.$emit('validate', this.validate)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

#page > .container {
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-top: 0.4rem;
  margin-bottom: 9rem;
  h4 {
    font-weight: 400;
    font-size: 2rem !important;
  }

  .my-profile-wrap {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    // padding: 0 3rem;
    padding: 0 !important;
  }
  
  .guide-btn {
    margin: 0.3rem 0 6rem;
    font-size: 2rem;
    font-weight: 600;
    color: #000;
  }

  textarea {
    line-height: 3rem;
    padding: 3rem 0;
    padding: 3rem 0;
    border: 0;
    width: 100%;
    min-height: 20rem;
    font-size: 1.6rem;
  }

  .media-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
    margin-bottom: 6rem;
    .media-item {
      overflow: hidden;
      position: relative;
      width: calc(50% - 0.8rem);
      height: 14rem;
      border-radius: 1.2rem;
      cursor: pointer;

      &.media-add-btn {
        border: 0.2rem dashed #ccc;
        border-radius: 1.2rem;
        background-color: #f8f8f8;
        .ui-inputbox {
          &.btn-add {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            .icon {
              height: 100%;
            }
          }
        }
      }

      .ui-bg-img {
        height: 17.2rem;
        border-radius: 0.5rem;
        background-color: #efefef;
      }

      .ui-icon-menu {
        display: none;
        margin: 6rem 0;
      }

      .item-handler {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(0,0,0,0.6);
        cursor: pointer;
      }

      .item-delete {
        position: absolute;
        top: 1.3rem;
        right: 1.3rem;
      }

      &.sortable-drag {
        background-color: #fefefe;
        box-shadow: 0 0.5rem 2rem 0 rgba($color: #000000, $alpha: 0.2);
      }

      .artist-badge {
        position: absolute;
        top: 1.3rem;
        left: 1.3rem;
        overflow: hidden;

        > div {
          display: inline-block;
          width: auto;
          padding: 0.4rem 0.8rem;
          border-radius: 0.6rem;
          background-color: #6433F0;
          color: #fff;
          font-size: 1.4rem;
          font-weight: 600;
          text-align: center;
        }
      }
    }
  }

  .ui-inputbox {
    padding: 0;
    input {
      padding-right: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .item-handler {
      float: right;
      // width: 45px;
      // height: 160px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &.btn-add {
      background-color: #f4f4f4;

      p {
        padding: 0;
        margin: 0;
        line-height: 3rem;
        font-size: inherit;
        color: #a9a9a9;
        border: none;
        width: 100%;
      }
    }

    .youtube-preview {
      position: relative;
      width: 284px;
      height: 160px;
      float: left;

      .youtube-preview-cover {
        width: inherit;
        height: inherit;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      .youtube-preview-player {
        width: inherit;
        height: inherit;
        position: relative;
        z-index: 0;
      }
    }
  }
}

</style>
