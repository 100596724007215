var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "secondary-tab" }, [
    _c(
      "div",
      { staticClass: "tab-btns" },
      [
        _vm._l(_vm.secondList, function(row, key) {
          return [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: row.isShow,
                    expression: "row.isShow"
                  }
                ],
                key: key,
                staticClass: "secondary-tab-btn",
                class: {
                  "btn-active": _vm.currentKey === key
                },
                on: {
                  click: function() {
                    return typeof row.action === "function" && row.action()
                  }
                }
              },
              [_vm._v(" " + _vm._s(row.label) + " ")]
            )
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }