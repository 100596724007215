<template>
  <div class="secondary-tab">
    <div class="tab-btns">
      <template v-for="(row, key) in secondList">
        <div
          v-show="row.isShow"
          :key="key"
          class="secondary-tab-btn"
          :class="{
            'btn-active': currentKey === key,
          }"
          @click="() => typeof row.action === 'function' && row.action()"
          >
          {{row.label}}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileDetailTab',
  props: {
    secondList: {
      type: Array,
      default: () => [],
    },
    currentKey: {
      type: Number,
      default: 0,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/css/constant.scss';

.secondary-tab {
  .tab-btns {
    overflow: scroll;
    display: flex;
    gap: 2rem;
    padding: 2rem 0;
    .secondary-tab-btn {
      flex-shrink: 0;
      height: 100%;
      padding: 0.8rem 1.05rem;
      border: 1px solid #EDEDED;
      border-radius: 3rem;
      color: #999999;
      font-size: 2rem;
      &.btn-active {
        border: 1px solid #000;
        color: #000;
        font-weight: 600;
      }
    }
  }
}
</style>
