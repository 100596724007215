<template>
    <modal ref="ArtistGuideModal" :useFooter="false">
        <ul slot="body">
            <li 
                @click="() => {
                    hide()
                    this.$emit('guide','sing')
                }" 
                class="text-center">
                가수, 보컬 분야
                <div class="ui-border-line"></div>
            </li>
            <li 
                @click="() => {
                    hide()
                    this.$emit('guide','mc')
                }" 
                class="text-center">
                사회자, 아나운서 분야
                <div class="ui-border-line"></div>
            </li>
            <li 
                @click="() => {
                    hide()
                    this.$emit('guide','host')
                }" 
                class="text-center">
                쇼호스트 분야
                <div class="ui-border-line"></div>
            </li>
            <li 
                @click="() => {
                    hide()
                    this.$emit('guide','etc')
                }" 
                class="text-center">
                연주, 댄스, DJ, 퍼포먼스 분야
                <div class="ui-border-line"></div>
            </li>
            <li 
                @click="() => {
                    hide()
                    this.$emit('guide','model')
                }" 
                class="text-center">
                모델 분야
                <div class="ui-border-line"></div>
            </li>
            <!-- <li @click="() => $gotoWeb('https://blog.naver.com/wimz0816/221983140186')" class="text-center">
                가수, 보컬 분야
                <div class="ui-border-line"></div>
            </li>
            <li @click="() => $gotoWeb('https://blog.naver.com/wimz0816/221983153085')" class="text-center">
                사회자, 아나운서 분야
                <div class="ui-border-line"></div>
            </li>
            <li @click="() => $gotoWeb('https://blog.naver.com/wimz0816/222641348147')" class="text-center">
                쇼호스트 분야
                <div class="ui-border-line"></div>
            </li>
            <li @click="() => $gotoWeb('https://blog.naver.com/wimz0816/221983160826')" class="text-center">
                연주, 댄스, DJ, 퍼포먼스 분야
                <div class="ui-border-line"></div>
            </li>
            <li @click="() => $gotoWeb('https://blog.naver.com/wimz0816/221983167131')" class="text-center">
                모델 분야
                <div class="ui-border-line"></div>
            </li> -->
            <li @click="() => hide()" class="text-center on">
                닫기
            </li>
        </ul>
    </modal>
</template>

<script>
import Modal from '@/components/common/Modal'

export default {
    name: 'ArtistGuideModal',
    components: {
        Modal,
    },
    methods: {
        show() {
            this.$refs.ArtistGuideModal.show()
        },
        hide() {
            this.$refs.ArtistGuideModal.hide()
        },
    },
}
</script>
