<template>
    <div>
        <div class="my-profile-wrap">
            <page-header
                :title="`네이버 쇼핑 라이브\n영상을 등록해주세요. `"
                :title-small="`(${formData.liveMov.length}/10)`"
            >
                <template slot="subtitle">
                네이버 영상 링크와 썸네일을 함께 등록하세요. <span class="red-font">(최소 5개)</span><br>
                재생이 되고 있는 라이브 영상 링크를 등록해주세요!<br>
                <span class="grey-font">(‘라이브가 종료되었습니다.’ 화면이 나오는 링크는 등록 불가)</span>
                </template>
            </page-header>

            <button type="button" class="guide-btn" @click="() => $refs.pageModal.show()">썸네일 가이드 보기 ></button>

            <div class="media-list">
                <div v-if="formData.liveMov.length < 10" class="media-item media-add-btn">
                    <div class="ui-inputbox ui-btn btn-add" @click="() => openNaverModal()">
                        <icon src="img/common/plus-gray.png" width="2.4rem" height="2.4rem" :cover="false" />
                    </div>
                </div>
                <div v-for="(row, key) in formData.liveMov" :key="key" class="media-item">
                    <div class="ui-inputbox">
                        <div class="inputbox--form">
                            <div class="form--body">
                                <template v-if="row.image">
                                    <div class="form--body--thumbnail"
                                        @click="() => {
                                            if (row.image) {
                                                openManageModal(key)
                                                return
                                            }
                                            selelctLiveMovImage(key)
                                        }">
                                        <img :src="$lib.cdnUrl(row.image)" />
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>

                    <button type="button" class="item-delete">
                        <icon src="img/common/close.png" width="2.8rem" height="2.8rem" :cover="false" @click="() => removeFormData(key)" />
                    </button>

                    <div class="item-handler" @click="() => openManageModal(key)">
                        <icon src="img/my-profile/edit.png" width="3.8rem" height="3.8rem" :cover="false" />
                    </div>
                </div>
            </div>

        </div>

        <page-modal
            ref="pageModal"
            :title="'썸네일 가이드'"
            :class="'close-right'"
        >
            <div slot="body" class="page-modal-body">
                <div class="guideLetter">
                    <icon src="img/my-profile/megaphone.png" width="1.8rem" height="1.8rem" :cover="false" />
                    따로 만들 필요 없어요!<br>
                    아래 내용에 맞는 방송 장면을 캡쳐해서 사용하세요.
                </div>
                <img
                    slot="body"
                    :src="$lib.imgUrl('img/my-profile/my-profile-naver-live-information02.png', true)"
                    alt="image"
                    :style="{
                    margin: 'auto',
                    display: 'block',
                    }"
                    >
                <div class="guide-list-wrap">
                    <div class="guide-list-tit">꼭 읽어보세요!</div>
                    <ul class="guide-list">
                    <li>판매하는 상품이 잘 보여야 해요.</li>
                    <li>본인 얼굴이 나와야 해요.</li>
                    <li>댓글이나 하트가 많이 보이면 정말 좋아요.</li>
                    <li class="emphasize">상품이 안보이는 썸네일 등록 불가</li>
                    </ul>
                </div>
            </div>
        </page-modal>

        <modal
            ref="naverModal"
            title="네이버 쇼핑 라이브 등록"
            confirm="등록"
            :confirmCb="confirmNaverModal"
            :hiddenCb="clearNaverModal"
            :useCancel="false"
            noBorder
            roundModal
            >
            <template slot="body">
                <div
                    class="form--body--thumbnail"
                    @click="() => selelctLiveMovImage(state.index)"
                >
                    <template v-if="naverModal.imageUrl">
                        <img :src="$lib.cdnUrl(naverModal.imageUrl)" />
                    </template>
                    <template v-else>
                        <div class="thumbnail-txt">썸네일 등록</div>
                        <icon src="img/common/plus-gray.png" width="2rem" height="2rem" :cover="false" />
                    </template>
                </div>
                <input
                v-model="naverModal.url"
                type="text"
                placeholder="url을 입력하세요"
                />
                <p class="input-notice" v-if="!isValidUrl" >※ 네이버 쇼핑라이브 URL만 등록 가능합니다.</p>
            </template>
        </modal>

        <modal ref="manageModal" :hidden-cb="clearManageModal" :use-footer="false">
            <ul slot="body">
                <li
                    class="text-center"
                    @click="
                        () => {
                            openNaverModal(state.index)
                            $refs.manageModal.hide()
                        }
                    "
                >
                    쇼핑라이브 수정하기
                    <div class="ui-border-line"></div>
                </li>
                <li
                    class="text-center"
                    @click="
                        () => {
                            removeFormData(state.index)
                            $refs.manageModal.hide()
                        }
                    "
                >
                    삭제하기
                    <div class="ui-border-line"></div>
                </li>
                <li class="text-center on" @click="() => $refs.manageModal.hide()">취소</li>
            </ul>
        </modal>
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import Icon from '@/components/common/Icon'
import TagBox from '@/components/common/TagBox'
import CheckBox from '@/components/common/CheckBox'
import Modal from '@/components/common/Modal'
import PageModal from '@/components/common/PageModal'
import router from '@/router'

export default {
    components: {
        PageHeader,
        Icon,
        TagBox,
        CheckBox,
        Modal,
        PageModal,
    },
    props: {
        artistData: {
        type: Object,
        required: true,
        },
    },
    data() {
        const artistData = this.artistData
        const liveMov: { image: string, url: string }[] = []

        if (Array.isArray(artistData.liveMov) && artistData.liveMov.length > 0) {
            for (const key in artistData.liveMov) {
                const row = artistData.liveMov[key]

                liveMov.push(row)
            }
        }

        return {
            formData: {
                liveMov,
            },
            naverModal: {
                index: -1,
                url: '',
                imageUrl: '',
            },
            state: {
                index: -1,
                url:'',
            },
            validate: {
                nav : 2,
                sub : 1,
                isValid: false,
            },
            isValidUrl: true,
        }
    },
    watch: {
        formData: {
        handler(newValue, oldValue) {
            // 전체 formData를 감시하여 특정 로직을 수행합니다.
            let formData = this.setFormData()
            this.$emit('update-data', formData)
            this.checkValid()
        },
        deep: true // 객체 내부의 속성까지 감시
        }
    },
    computed: {
        validLiveMovList() {
            return this.formData.liveMov.filter(row => row.url && row.image)
        },
    },
    created() {
        this.checkValid()
    },
    mounted() {
        this.$root.$on('img-uploader:response', (uid, success, url) => {
            if (this._uid === uid && success) {
                this.setLiveMovImage(this.state.index, url)
                this.$refs.manageModal.hide()
            }
        })
    },
    methods: {
        validateUrl(url) {
            const validKeywords = ['shoppinglive.naver', 'naver.me']
            let isValid = true

            if (!url || validKeywords.every(validKeyword => !url.includes(validKeyword))) {
                isValid = false
            }

            return isValid
        },

        setFormData() {
            const formData = {
                liveMov: [],
                liveUrl: [],
            }

            for (const index in this.formData.liveMov) {
                const row = this.formData.liveMov[index]

                formData.liveMov.push(row.image)
                formData.liveUrl.push(row.url)
            }

            return formData
        },

        addFormData() {
            if (this.formData.liveMov.length < 10) {
                this.formData.liveMov.push({
                    image: '',
                    url: '',
                })
            }
        },
        openNaverModal(index = -1) {
            let nextUrl = ''
            let nextImageUrl = ''
            if (index !== -1) {
                const liveMov = this.formData.liveMov[index]

                if (!liveMov) {
                    index = -1
                } else {
                    nextUrl = liveMov?.url || ''
                    nextImageUrl = liveMov?.image || ''
                }
            }
            this.naverModal.index = index
            this.naverModal.imageUrl = nextImageUrl
            this.naverModal.url = nextUrl
            this.$refs.naverModal.show()
        },
        confirmNaverModal(modal) {
            const { url, imageUrl, index } = this.naverModal

            if (!this.validateUrl(url)) {
                this.isValidUrl = false;
                return
            }else{
                this.isValidUrl = true;
            }

            if(imageUrl == null || imageUrl == ''){
                this.$toast('썸네일을 등록해주세요!');
                return
            }
            

            if (index === -1) {
                this.formData.liveMov.push({
                url: url,
                image: imageUrl,
                })
            } else {
                this.formData.liveMov[index].url = url
                this.formData.liveMov[index].image = imageUrl
            }
            this.state.index = this.formData.liveMov.length
            modal.hide()
        },
        clearNaverModal() {
            this.naverModal.index = -1
            this.naverModal.url = ''
            this.state.index = -1
            this.state.url = ''
            this.isValidUrl = true;
        },
        openManageModal(index = -1) {
            this.state.index = index
            this.$refs.manageModal.show()
        },

        clearManageModal() {
            this.state.index = -1
        },

        selelctLiveMovImage(index = -1) {
            if (index != -1) this.state.index = index

            this.$root.$emit('img-uploader:request', this._uid, {
                cropperOption: {
                    aspectRatio: 805 / 1109,
                },
                getCroppedCanvasOption: {
                    maxWidth: 805,
                    maxHeight: 1109,
                },
            })
        },

        removeFormData(index = -1) {
            if (index === -1) return

            this.formData.liveMov.splice(index, 1)

            if (this.formData.liveMov.length === 0) {
               // this.addFormData()
            }
        },

        setLiveMovImage(index = -1, url: string) {
            if (index === -1){
                this.state.url = url
                this.naverModal.imageUrl = url
                return
            } 
            //this.formData.liveMov[index].image = url || ''
            this.formData.liveMov.splice(index, 0, { 'image': url, 'url': '' })
            this.$forceUpdate()
        },
        checkValid(){
            let isValid = this.validLiveMovList.length >= 5
            this.validate.isValid = isValid;

            this.$emit('validate', this.validate)
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

// .ui-progress-bar {
//     position: relative;
//     top: 0;
//     left: -3rem;
//     max-width: none;
// }
.ui-navTop {
    margin: 0 -3rem;
}

.guide-btn {
    margin: 0.3rem 0 6rem;
    font-size: 2rem;
    font-weight: 600;
    color: #000;
}

.media-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
    margin-bottom: 6rem;
    .media-item {
        overflow: hidden;
        position: relative;
        width: calc(50% - 0.8rem);
        height: 34.8rem;
        border-radius: 1.2rem;
        cursor: pointer;

        &.media-add-btn {
            border: 0.2rem dashed #ccc;
            border-radius: 1.2rem;
            background-color: #f8f8f8;
            .ui-inputbox {
                &.btn-add {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    .icon {
                    height: 100%;
                    }
                }
            }
        }

        .ui-bg-img {
            height: 17.2rem;
            border-radius: 0.5rem;
            background-color: #efefef;
        }

        .ui-icon-menu {
            display: none;
            margin: 6rem 0;
        }

        .item-handler {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0,0,0,0.6);
            cursor: pointer;
        }

        .item-delete {
            position: absolute;
            top: 1.3rem;
            right: 1.3rem;
        }

        &.sortable-drag {
            background-color: #fefefe;
            box-shadow: 0 0.5rem 2rem 0 rgba($color: #000000, $alpha: 0.2);
        }

        .artist-badge {
            position: absolute;
            top: 1.3rem;
            left: 1.3rem;
            overflow: hidden;

            > div {
            display: inline-block;
            width: auto;
            padding: 0.4rem 0.8rem;
            border-radius: 0.6rem;
            background-color: #6433F0;
            color: #fff;
            font-size: 1.4rem;
            font-weight: 600;
            text-align: center;
            }
        }
    }
}

.ui-inputbox {
    padding: 0;
}

.ui-inputbox {
    height: 100%;
    // margin: 0 -3rem;
    // padding: 2rem 3rem;

    .inputbox--form {
        height: 100%;
        // display: flex;
        // margin-bottom: 20px;

        &:first-child {
            // margin-top: 10px;
        }

        &:last-child {
            // margin-bottom: 10px;
        }

        .form--title {
            // width: 90px;
            // margin-right: 14px;
        }

        .form--body {
            height: 100%;

            // flex: 1;

            .form--body--url {
                // font-size: 1.9rem;
                // padding: 10px 15px;
                // border-radius: 5px;
                // border: 1px solid #979797;
                // background: transparent;
                // color: #000;

                // &::placeholder {
                //     color: #b6b6b6;
                // }
            }

            .form--body--thumbnail {
                height: 100%;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    &.btn-add {
        background-color: #f4f4f4;

        p {
            padding: 0;
            margin: 0;
            line-height: 3rem;
            font-size: inherit;
            color: #a9a9a9;
            border: none;
            width: 100%;
        }

        // .icon {
        //     position: absolute;
        //     top: 0;
        //     right: 3rem;
        //     transform: rotate(0deg);
        // }
    }
}
.modal {
    .form--body--thumbnail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.4rem;
        width: 18rem;
        height: 27rem;
        margin: 0 auto 2rem;
        border: 1px dashed #CCCCCC;
        border-radius: 0.8rem;
        background-color: #F8F8F8;
        .thumbnail-txt {
            color: #A8A8A8;
            font-size: 1.6rem;
        }
    }
}

.page-modal-body {
    margin: 0 !important;
    margin-top: -4rem !important;
    .guideLetter {
      display: flex;
      gap: 1rem;
      margin-bottom: 6rem;
      padding: 1.2rem 2rem;
      border-radius: 0.8rem;
      background-color: rgba(#AB54BB,0.1);
      font-size: 1.6rem;
      line-height: 2.1rem;
      color: #AB54BB;
    }
    .guide-list-wrap {
      margin-top: 4rem;
      .guide-list-tit {
        font-size: 2.6rem;
        font-weight: 600;
      }
      .guide-list {
        margin-top: 0.8rem;
        li {
          margin-left: 2rem;
          font-size: 1.9rem;
          color: #666;
          list-style: disc;
          &.emphasize {
            color: #F33841;
          }
          & ~ li {
            margin-top: 0.8rem;
          }
        }
      }
    }
  }
</style>
