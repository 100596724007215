<template>
  <div class="container">
    <ProfileDetailTab :second-list="secondTab.list" :current-key="secondTab.currentKey" />
    <active-img v-show="secondTab.currentKey == 0" :artistData="artistData" @update-data="updateArtistData" @validate="handleValidate"></active-img>
    <active-naver-live v-show="secondTab.currentKey == 1" :artistData="artistData" @update-data="updateArtistData" @validate="handleValidate"></active-naver-live>
    <active-video v-show="secondTab.currentKey == 2" :artistData="artistData" @update-data="updateArtistData" @validate="handleValidate"></active-video>
    <active-etc v-show="secondTab.currentKey == 3" :artistData="artistData" @update-data="updateArtistData" @validate="handleValidate"></active-etc>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import ProfileDetailTab from '@/views/profile/ProfileDetailTab.vue'
import ActiveImg from '@/views/artist/my_profile/ActiveImg.vue'
import ActiveVideo from '@/views/artist/my_profile/ActiveVideo.vue'
import ActiveNaverLive from '@/views/artist/my_profile/ActiveNaverLive.vue'
import ActiveEtc from '@/views/artist/my_profile/ActiveEtc.vue'

export default {
  components: {
    Draggable,
    'active-img':ActiveImg,
    ProfileDetailTab,
    'active-video': ActiveVideo,
    'active-naver-live':ActiveNaverLive,
    'active-etc':ActiveEtc,
  },
  props: {
    artistData: {
      type: Object,
      required: true,
    },
    secondTab: {
      type: Object,
    }
  },
  methods: {
    updateArtistData(item){
      let formData = { ...this.artistData , ...item }
      this.$emit('update-data', formData)
    },
    handleValidate(item){
      this.$emit('validate', item)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

#page > .container {
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-top: 0.4rem;
  margin-bottom: 9rem;  
  h4 {
    font-weight: 400;
    font-size: 2rem !important;
  }

  .my-profile-wrap {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 3rem;
  }
  .guide-btn {
    margin: 0.3rem 0 6rem;
    font-size: 2rem;
    font-weight: 600;
  }

  .image-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
    margin-bottom: 6rem;
    .image-item {
      overflow: hidden;
      position: relative;
      width: calc(50% - 0.8rem);
      border-radius: 1.2rem;
      cursor: pointer;

      &.image-add-btn {
        border: 0.2rem dashed #ccc;
        border-radius: 1.2rem;
        background-color: #f8f8f8;
        .ui-inputbox {
          &.btn-add {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            .icon {
              height: 100%;
            }
          }
        }
      }

      .ui-bg-img {
        height: 17.2rem;
        border-radius: 0.5rem;
        background-color: #efefef;
      }

      .ui-icon-menu {
        display: none;
        margin: 6rem 0;
      }

      .item-handler {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(0,0,0,0.6);
        cursor: pointer;
      }

      .item-delete {
        position: absolute;
        top: 1.3rem;
        right: 1.3rem;
      }

      &.sortable-drag {
        background-color: #fefefe;
        box-shadow: 0 0.5rem 2rem 0 rgba($color: #000000, $alpha: 0.2);
      }

      .artist-badge {
        position: absolute;
        top: 1.3rem;
        left: 1.3rem;
        overflow: hidden;

        > div {
          display: inline-block;
          width: auto;
          padding: 0.4rem 0.8rem;
          border-radius: 0.6rem;
          background-color: #6433F0;
          color: #fff;
          font-size: 1.4rem;
          font-weight: 600;
          text-align: center;
        }
      }
    }
  }

  textarea {
    line-height: 3rem;
    padding: 3rem 0;
    padding: 3rem 0;
    border: 0;
    width: 100%;
    min-height: 20rem;
    font-size: 1.6rem;
  }

  .page-modal-body {
    margin: 0;
    margin-top: -4rem;
    .guideLetter {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 6rem;
      padding: 1.2rem 2rem;
      border-radius: 0.8rem;
      background-color: rgba(#AB54BB,0.1);
      font-size: 1.6rem;
      line-height: 2.1rem;
      color: #AB54BB;
    }
    .guide-list-wrap {
      margin-top: 4rem;
      .guide-list-tit {
        font-size: 2.6rem;
        font-weight: 600;
      }
      .guide-list {
        margin-top: 0.8rem;
        li {
          margin-left: 2rem;
          font-size: 1.9rem;
          color: #666;
          list-style: disc;
          &.emphasize {
            color: #F33841;
          }
          & ~ li {
            margin-top: 0.8rem;
          }
        }
      }
    }
  }
}
</style>
