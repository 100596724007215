var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-profile-wrap" },
    [
      _c(
        "page-header",
        {
          attrs: {
            title: "전문성과 실력을 보일 수 있는\n영상을 등록해주세요. ",
            "title-small": "(" + _vm.formData.movData.length + "/20)"
          }
        },
        [
          _c("template", { slot: "subtitle" }, [
            _vm._v(" 등록 가이드에 맞는 영상을 등록하세요. "),
            _c("span", { staticClass: "red-font" }, [_vm._v("(최소 5개)")]),
            _c("br"),
            _c("span", { staticClass: "grey-font" }, [
              _vm._v("(영상 추가 후, 반드시 미리보기를 진행해주세요!)")
            ])
          ])
        ],
        2
      ),
      _c(
        "button",
        {
          staticClass: "guide-btn",
          attrs: { type: "button" },
          on: {
            click: function() {
              return _vm.$refs.ArtistGuideModal.show()
            }
          }
        },
        [_vm._v(" 등록 가이드 보기 >")]
      ),
      _c(
        "div",
        { staticClass: "media-list" },
        [
          _vm.formData.movData.length < 20
            ? _c("div", { staticClass: "media-item media-add-btn" }, [
                _c(
                  "div",
                  {
                    staticClass: "ui-inputbox ui-btn btn-add",
                    on: {
                      click: function() {
                        return _vm.openYoutubeModal()
                      }
                    }
                  },
                  [
                    _c("icon", {
                      attrs: {
                        src: "img/common/plus-gray.png",
                        width: "2.4rem",
                        height: "2.4rem",
                        cover: false
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._l(_vm.formData.movData, function(row, key) {
            return _c("div", { key: key, staticClass: "media-item" }, [
              _c(
                "div",
                { staticClass: "ui-inputbox" },
                [
                  _vm.$youtube.getIdFromUrl(row.movUrl) !== null
                    ? _c("youtube", {
                        style: {
                          width: "360px",
                          height: "140px"
                        },
                        attrs: {
                          "video-id": _vm.$youtube.getIdFromUrl(row.movUrl),
                          "player-vars": { rel: 0, showinfo: 0 }
                        }
                      })
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "item-delete",
                      attrs: { type: "button" },
                      on: {
                        click: function() {
                          return _vm.deleteData(key)
                        }
                      }
                    },
                    [
                      _c("icon", {
                        attrs: {
                          src: "img/common/close.png",
                          width: "2.8rem",
                          height: "2.8rem",
                          cover: false
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-handler",
                      on: {
                        click: function() {
                          return _vm.openManageModal(key)
                        }
                      }
                    },
                    [
                      _c("icon", {
                        attrs: {
                          src: "img/my-profile/edit.png",
                          width: "3.8rem",
                          height: "3.8rem",
                          cover: false
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          })
        ],
        2
      ),
      _c(
        "page-modal",
        {
          ref: "pageModal",
          attrs: {
            title: "없으면 손해 보는 필수 영상!",
            subtitle: "의뢰인들은 아래의 영상이 있는 분들을 격하게 선호해요."
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "page-modal-body",
              attrs: { slot: "body" },
              slot: "body"
            },
            [
              _c("img", {
                style: {
                  margin: "auto",
                  display: "block",
                  padding: "10% 10% 20%"
                },
                attrs: {
                  slot: "body",
                  src: _vm.$lib.imgUrl(
                    "img/my-profile/my-profile-movie-information.png",
                    true
                  ),
                  alt: "image"
                },
                slot: "body"
              })
            ]
          )
        ]
      ),
      _c(
        "modal",
        {
          ref: "youtubeModal",
          attrs: {
            title: "유튜브 URL 등록",
            confirm: "등록",
            confirmCb: _vm.confirmYoutubeModal,
            hiddenCb: _vm.clearYoutubeModal,
            useCancel: false,
            noBorder: "",
            roundModal: ""
          }
        },
        [
          _c("template", { slot: "body" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.youtubeModal.url,
                  expression: "youtubeModal.url"
                }
              ],
              attrs: { type: "text", placeholder: "url을 입력하세요" },
              domProps: { value: _vm.youtubeModal.url },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.youtubeModal, "url", $event.target.value)
                }
              }
            }),
            !_vm.isValidUrl
              ? _c("p", { staticClass: "input-notice" }, [
                  _vm._v("※ 유튜브 URL만 등록 가능합니다.")
                ])
              : _vm._e()
          ])
        ],
        2
      ),
      _c(
        "modal",
        {
          ref: "manageModal",
          attrs: {
            "hidden-cb": _vm.clearManageModal,
            "use-footer": false,
            roundModal: true
          }
        },
        [
          _c("ul", { attrs: { slot: "body" }, slot: "body" }, [
            _c(
              "li",
              {
                staticClass: "text-center",
                on: {
                  click: function() {
                    _vm.openYoutubeModal(_vm.manageModal.index)
                    _vm.$refs.manageModal.hide()
                  }
                }
              },
              [
                _vm._v(" 링크 수정 "),
                _c("div", { staticClass: "ui-border-line" })
              ]
            ),
            _c(
              "li",
              {
                staticClass: "text-center",
                on: {
                  click: function() {
                    _vm.formData.movData.splice(_vm.manageModal.index, 1)
                    _vm.$refs.manageModal.hide()
                  }
                }
              },
              [
                _vm._v(" 영상 삭제 "),
                _c("div", { staticClass: "ui-border-line" })
              ]
            ),
            _c(
              "li",
              {
                staticClass: "text-center on",
                on: {
                  click: function() {
                    return _vm.$refs.manageModal.hide()
                  }
                }
              },
              [_vm._v("취소")]
            )
          ])
        ]
      ),
      _c("ArtistGuideModal", {
        ref: "ArtistGuideModal",
        on: {
          guide: function(genre) {
            return _vm.$refs.YoutubeGuideModal.show(genre)
          }
        }
      }),
      _c("YoutubeGuideModal", { ref: "YoutubeGuideModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }