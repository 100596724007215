<template>
  <div class="my-profile-wrap">
    <div class="textarea-wrap">
      <h4 :style="{fontWeight: 500}">활동경력, 전공, 수상내역 등을 상세히 작성<span class="essential">*</span></h4>
      <textarea
        name="playDesc"
        :placeholder="`예) OO대학교 OO과 졸업\n2018년 OO대회 우수상 수상\nOO기업 연말 행사 축하공연`"
        v-model="formData.playDesc"
        @input="inputHandler"
        @keyup.enter="keyPress"
      >
      </textarea>
      <span class="counting" :class="(formData.playDesc && lengths['playDesc']>=50) ?'check':''">({{lengths['playDesc']}}/ 최소 50자)</span>
    </div>

    <div class="textarea-wrap">
      <h4 :style="{fontWeight: 500}">당신을 섭외해야 하는 이유<br/> 3가지를 알려주세요.<span class="essential">*</span></h4>
      <div class="input-help">본인만의 장점이나 매력, 필요성 등을 어필하세요.<br/>
      * 상세할수록 어필이 잘 돼요!</div>
      <textarea
        name="interview1"
        v-model="formData.interview1"
        @input="inputHandler"
        @keyup.enter="keyPress"
      >
      </textarea>
      <span class="counting" :class="(formData.interview1 && lengths['interview1']>=30) ?'check':''">({{lengths['interview1']}}/ 최소 30자)</span>
    </div>

    <div class="textarea-wrap">
      <h4 :style="{fontWeight: 500}">의뢰자, 대중을 만족시키는 <br>본인만의 방법은 무엇인가요?<span class="essential">*</span></h4>
      <div class="input-help">노하우 또는 중요시하는 점들을 상세히 기재</div>
      <textarea
        name="interview2"
        v-model="formData.interview2"
        @input="inputHandler"
        @keyup.enter="keyPress"
      >
      </textarea>
      <span class="counting" :class="(formData.interview2 && lengths['interview2']>=50) ?'check':''">({{lengths['interview2']}}/ 최소 50자)</span>
    </div>

    <div class="textarea-wrap">
      <h4 :style="{fontWeight: 500}">의뢰자에게 어떤 전문가로 <br>기억되고 싶으세요?<span class="essential">*</span></h4>
      <div class="input-help">기억 또는 비춰지길 바라는 모습 등</div>
      <textarea
        name="interview3"
        v-model="formData.interview3"
        @input="inputHandler"
        @keyup.enter="keyPress"
      >
      </textarea>
      <span class="counting" :class="(formData.interview3 && lengths['interview3']>=30) ?'check':''">({{lengths['interview3']}}/ 최소 30자)</span>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon'
import TagBox from '@/components/common/TagBox'
import CheckBox from '@/components/common/CheckBox'
import Modal from '@/components/common/Modal'
import router from '@/router'

export default {
  components: {
    Icon,
    TagBox,
    CheckBox,
    Modal,
  },
  props: {
    artistData: {
      type: Object,
      required: true,
    },
  },
  data() {
    const artistData = this.artistData

    return {
      formData: {
        playDesc: artistData.playDesc || null,
        interview1: artistData.interview1 || null,
        interview2: artistData.interview2 || null,
        interview3: artistData.interview3 || null,
      },
      validate: {
        nav : 2,
        sub : 3,
        isValid: false,
      },
      lengths: {
        playDesc: artistData.playDesc ? artistData.playDesc.length : 0,
        interview1: artistData.interview1 ? artistData.interview1.length : 0,
        interview2: artistData.interview2 ? artistData.interview2.length : 0,
        interview3: artistData.interview3 ? artistData.interview3.length : 0,
      },
    }
  },
  watch: {
    formData: {
      handler(newValue, oldValue) {
        this.$emit('update-data', newValue)
        this.checkValid()
      },
      deep: true
    }
  },
  created() {
    this.checkValid()
  },
  methods: {
    inputHandler(e:any){
      const target = e.target;
      let type = e.target.getAttribute('name');
      this.lengths[type]=target.value.length;
    },
    checkValid(){
      const isValid = Object.keys(this.formData).reduce(
          (result, key) => {
            if (key === 'playDesc' && this.formData[key] && this.lengths[key] < 50) return false
            if (key === 'interview1' && this.formData[key] && this.lengths[key] < 30) return false
            if (key === 'interview2' && this.formData[key] && this.lengths[key] < 50) return false
            if (key === 'interview3' && this.formData[key] && this.lengths[key] < 30) return false
            return result === true && this.formData[key] !== null && this.formData[key] !== ''
          },
          true
        );

      this.validate.isValid = isValid;

      this.$emit('validate', this.validate)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';
#page > .container {
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-top: 0.4rem;
  margin-bottom: 9rem;

  h4 {
    margin-top: 0;
    font-weight: 400;
    font-size: 2.5rem !important;
    line-height: 1.3;
    .essential {
      display: inline-block;
      margin-left: 0.2rem;
      color: #F33841;
    }
    & ~ .input-help {
      margin-top: -1.5rem;
      margin-bottom: 1.4rem;
      font-size: 1.9rem;
      color: #999;
    }
  }

  .my-profile-wrap {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    // padding: 0 3rem;
    padding: 0 !important;
  }

  .textarea-wrap {
    position: relative;
    margin-top: 3rem;
    margin-bottom: 6rem;
    padding-bottom: 2rem;
    textarea {
      width: 100%;
      min-height: 22rem;
      padding: 2.4rem 3rem;
      border: 1px solid #DDD;
      border-radius: 0.8rem;
      line-height: 3rem;
      font-size: 2.2rem;
    }
    .counting {
      position: absolute;
      right: 0;
      bottom: 0;
      color: #F33841;
      font-size: 1.4rem;
      &.check {
        color: #00A573;
      }
    }
    & ~ .textarea-wrap {
      margin-top: 0;
    }
  }
}

</style>
