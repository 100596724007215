var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("ProfileDetailTab", {
        attrs: {
          "second-list": _vm.secondTab.list,
          "current-key": _vm.secondTab.currentKey
        }
      }),
      _c("active-img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.secondTab.currentKey == 0,
            expression: "secondTab.currentKey == 0"
          }
        ],
        attrs: { artistData: _vm.artistData },
        on: {
          "update-data": _vm.updateArtistData,
          validate: _vm.handleValidate
        }
      }),
      _c("active-naver-live", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.secondTab.currentKey == 1,
            expression: "secondTab.currentKey == 1"
          }
        ],
        attrs: { artistData: _vm.artistData },
        on: {
          "update-data": _vm.updateArtistData,
          validate: _vm.handleValidate
        }
      }),
      _c("active-video", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.secondTab.currentKey == 2,
            expression: "secondTab.currentKey == 2"
          }
        ],
        attrs: { artistData: _vm.artistData },
        on: {
          "update-data": _vm.updateArtistData,
          validate: _vm.handleValidate
        }
      }),
      _c("active-etc", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.secondTab.currentKey == 3,
            expression: "secondTab.currentKey == 3"
          }
        ],
        attrs: { artistData: _vm.artistData },
        on: {
          "update-data": _vm.updateArtistData,
          validate: _vm.handleValidate
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }