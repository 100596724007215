<template>
  <div class="container">
    <progress-bar :percent="formData.percent"></progress-bar>
    <nav-top :list="nav.list" :current-key="nav.currentKey" />
    <Default v-show="nav.currentKey == 0" :artistData="formData" @update-data="updateArtistData" @validate="handleValidate"></Default>
    <Detail v-show="nav.currentKey == 1" :artistData="formData" @update-data="updateArtistData"  @validate="handleValidate"></Detail>
    <Active v-show="nav.currentKey == 2" :secondTab="secondTabs" :artistData="formData" @update-data="updateArtistData"  @validate="handleValidate"></Active>

    <footer-box
      :single="false"
      submitText="다음"
      :submitCb="() => nextStep()"
      :submitDisabled="!validate"
      btnText="미리보기"
      :btnCb="() => preview()"
      small-btn
    >
    </footer-box>
  </div>
</template>
<script>
import FooterBox from '@/components/common/FooterBox'
import NavTop from '@/components/common/NavTop.vue'
import ProgressBar from '@/components/common/ProgressBar.vue'
import Modal from '@/components/common/Modal'
import Default from '@/views/artist/my_profile/Default.vue'
import Detail from '@/views/artist/my_profile/Detail.vue'
import Active from '@/views/artist/my_profile/Active.vue'
import { ArtistDataInfo } from '@/model/ArtistModel'
export default {
  components: {
    FooterBox,
    Modal,
    Default,
    Detail,
    Active,
    NavTop,
    ProgressBar,
  },
  data() {
    const artistData = this.$store.getters.getArtistData;
    return {
      artistData,
      artistProfileStep : this.$store.getters.getArtistProfileStep,
      formData: artistData,
      modalData: {
        birth: null,
      },
      init: false,
      scroll: {
          lastY: [0, 0, 0],
      },
      nav: {
        list: [
            {
                label: '기본 정보',
                action: () => {
                  this.nav.currentKey = 0
                },
                key: 0
            },
            {
                label: '상세 정보',
                action: () => {
                  this.nav.currentKey = 1;
                },
                key: 1
            },
            {
                label: '활동 정보',
                action: () => {
                  this.nav.currentKey = 2;
                },
                key: 2
            },
        ],
        currentKey: 0,
      },
      secondTab: {
        list: [
            {
                label: '이미지',
                action: () => {
                 this.secondTab.currentKey = 0;
                },
                isShow: true,
            },
            {
                label: '쇼핑라이브',
                action: () => {
                  this.secondTab.currentKey = 1;
                },
                isShow: false,
            },
            {
                label: '동영상',
                action: () => {
                  this.secondTab.currentKey = 2;
                },
                isShow: true,
            },
            {
                label: '부가정보',
                action: () => {
                 this.secondTab.currentKey = 3;
                },
                isShow: true,
            },
        ],
        currentKey: 0,
      },
      history: {
        exit: false,
      },
      tooltip: {
        profile: false,
      },
      valid: {
        0: { isValid: false },
        1: { isValid: false },
        2: { sub: {
              0 : { isValid: false },
              1 : { isValid: false },
              2 : { isValid: false },
              3 : { isValid: false },
          } }
      },
    }
  },
  mounted() {
    this.$store.commit('setGnbRightBtnCb', this.gnbRightBtnCb)
  },
  computed: {
    validate: {
      get() {
        let validate = true

        switch (this.nav.currentKey){
          case 0:
            validate = this.valid[0].isValid
            break;
          case 1:
            validate = this.valid[1].isValid
            break;
          case 2:
            validate = this.valid[2].sub[this.secondTab.currentKey].isValid
            break;
        }
        return validate;
      },
    },
    secondTabs: {
      get() {
        return this.secondTab;
      }
    }
  },
  methods: {
    updateArtistData(item){
      this.formData = { ...this.formData, ...item }
    },
    handleValidate(item){
      if( item.nav !=2 ){
        this.valid[item.nav].isValid = item.isValid
      } else{
        this.valid[item.nav].sub[item.sub].isValid = item.isValid
      }
      if(this.formData.genre == '쇼호스트' || (this.formData.genre=='MC' && this.formData.genreType.split('|').includes('쇼호스트')) ){
        this.secondTab.list[1].isShow = true;
      } else {
        this.secondTab.list[1].isShow = false;
      }
    },
    gnbRightBtnCb() {
      // ArtistDataInfo 모델과 일치하는 속성만 추출
      const reqData = new ArtistDataInfo();
      Object.keys(reqData).forEach(key => {
        reqData[key] = this.formData[key];
        if(reqData[key] == null || reqData[key] == undefined){
          delete reqData[key];
        }
      });

      // 승인상태에 따라 필수값 체크
      let check = true;
      if(this.artistProfileStep == 2 || this.artistProfileStep == 3){
        if(this.formData.genre == '쇼호스트' || (this.formData.genre == 'MC' && this.formData.genreType.split('|').includes('쇼호스트'))){
          check = this.checkValidity(this.valid);
        }else{
          check = this.checkValidity(this.valid,1);
        }
      }

      if(check){
        this.$store.dispatch('saveProfile', this.formData).then(() => {
          this.$router.push('/artist/my-profile')
        })
      }else{// 승인대기 혹은 승인완료 상태에서 필수값 미입력시 안내팝업 노출
        let message = '필수 정보를 모두 입력해주세요.';
        if(this.artistProfileStep == 2) message = '이미 심사 요청중인 프로필입니다.\n필수 정보를 모두 입력해주세요.';
        else message = '이미 승인 완료된 프로필입니다.\n필수 정보를 모두 입력해주세요.';
        this.$store.commit('setModal', {
            show: true,
            body: message,
            useCancel: false,
            confirm: '확인',
            confirmCb: modal => {
                modal?.hide?.()
            },
        })
        return
      }
    },
    checkValidity(valid, skipIndex = null) {
        for (const key in valid) {
            if (valid.hasOwnProperty(key)) {
                if (valid[key].hasOwnProperty('isValid') && valid[key].isValid === false) {
                    return false;
                } else if (valid[key].hasOwnProperty('sub')) {
                  for (const key2 in valid[key].sub) {
                    if (valid[key].sub.hasOwnProperty(key2)) {
                      if (skipIndex != null && key2 == skipIndex) {
                        continue; // skipIndex와 일치할 때 건너뜁니다.
                      }else{
                        if (valid[key].sub[key2].hasOwnProperty('isValid') && valid[key].sub[key2].isValid === false) {
                          return false;
                        } 
                      }
                    }
                  }
                }
            }
        }
        return true;
    },
    nextStep() {
      if(this.nav.currentKey == 2) {
        if(this.secondTab.currentKey == 3){
          this.$store.dispatch('saveProfile', this.formData).then(() => {
            this.$router.push('/artist/my-profile')
          })
        } else if (this.secondTab.currentKey == 0 && !(this.formData.genre == '쇼호스트' || (this.formData.genre == 'MC' && this.formData.genreType.split('|').includes('쇼호스트')))) {
          this.secondTab.currentKey += 2;
        } else {
          this.secondTab.currentKey += 1;
        }
      } else {
        this.nav.currentKey += 1;
      }
    },
    preview() {
      let updateData = { ...this.artistData, ...this.formData };
      this.$store.dispatch('updateProfile', updateData).then(() => {
        this.$router.push('/artist/my-profile/preview')
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

#page > .container {
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-top: 0.4rem;
  padding-bottom: 9rem;
  .page-header {
    padding-bottom: 0;
  }
  h4 {
    margin-top: 0;
    font-weight: 400;
    font-size: 2.5rem !important;
    .essential {
      display: inline-block;
      margin-left: 0.2rem;
      color: #F33841;
    }
  }
  .my-profile-wrap {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 3rem;
    .form-wrap {
      margin-top: 3rem;
      margin-bottom: 6rem;
      .ui-inputbox {
        padding: 0;
        &.typing-count {
          position: relative;
          input {
            padding-right: 7.5rem;
          }
          .counting {
            position: absolute;
            right: 3rem;
            top: 50%;
            color: #a8a8a8;
            font-size: 1.4rem;
            transform: translateY(-50%);
          }
        }
        input {
          padding: 2.2rem 3rem;
          border: 0.1rem solid #DDDDDD;
          border-radius: 0.8rem;
          line-height: 2.8rem;
          &::placeholder {
            color: #a8a8a8 !important;
          }
        }
      }
      & ~ .form-wrap {
        margin-top: 0;
      }
    }
    .ui-tag-box {
      li {
        margin-bottom: 0;
      }
    }
    .ui-check-box {
      padding: 2.2rem 3rem;
      border: 1px solid #DDDDDD;
      border-radius: 0.8rem;
      &.on {
        color: black;
      }
    }
  }
}

</style>
