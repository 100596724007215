<template>
  <div class="my-profile-wrap">
    <page-header
      v-if="$lib.validMultipleGenre(this.artistData.genre, '모델')"
      :title="`다양한 컨셉의 모델 사진을\n등록해주세요. (${listData.length}/10)`">
      <template slot="subtitle">
        표정, 의상, 분위기 등이 다양할수록 좋아요! <span class="red-font">(최소 3개)</span>
      </template>
    </page-header>
    <page-header
      v-else
      :title="`전문성이 돋보이는\n고화질 사진을 등록하세요. `"
      :title-small="`(${listData.length}/10)`"
      >
      <template slot="subtitle">
        첫번째 사진은 섭외에 아주 큰 영향을 끼쳐요!<br/>고화질의 프로필 사진을 등록하세요. <span class="red-font">(최소 3개)</span>
      </template>
    </page-header>
    <button type="button" class="guide-btn" @click="() => $refs.pageModal.show()">반응 좋은 사진 고르는 방법 보기 ></button>
    <draggable
      class="image-list"
      v-model="listData"
      :options="{
        draggable: '.image-item',
        handle: '.ui-icon-menu',
        disabled: true, // 비활성화
      }
    ">
      <div v-if="listData.length < 10"  class="image-item image-add-btn">
        <div class="ui-inputbox ui-btn btn-add" @click="() => openActionModal()">
          <icon src="img/common/plus-gray.png" width="2.4rem" height="2.4rem" :cover="false" />
        </div>
      </div>
      <div
        v-for="(row, key) in listData" :key="key"
        class="image-item"
      >
        <div class="ui-bg-img" :style="{ backgroundImage: row ? `url(${$lib.cdnUrl(row)})` : null }"></div>

        <div v-if="key==0" class="artist-badge">
          <div>대표</div>
        </div>

        <button type="button" class="item-delete"  @click="() => removeData(key)">
          <icon src="img/common/close.png" width="2.8rem" height="2.8rem" :cover="false" />
        </button>

        <div class="item-handler" @click="() => openActionModal(key)">
          <icon src="img/my-profile/edit.png" width="3.8rem" height="3.8rem" :cover="false" />
        </div>

        <div class="ui-icon-menu">
          <div class="ui-icon-menu-bar"></div>
          <div class="ui-icon-menu-bar"></div>
          <div class="ui-icon-menu-bar"></div>
        </div>
      </div>

    </draggable>
    <modal
      ref="modal"
      :hidden-cb="() => {
        modalData.key = null
        modalData.action = null
      }"
      :use-footer="false"
    >
      <ul slot="body">
        <li
          class="text-center"
          :class="{ on: modalData.action === 'post' }"
          @click="() => {
            modalData.action = 'post'
            confirmActionModal($refs.modal)
          }"
        >
          사진 선택하기
          <div class="ui-border-line"></div>
        </li>
        <li
          class="text-center"
          :class="{ on: modalData.action === 'delete' }"
          @click="() => {
            modalData.action = 'delete'
            confirmActionModal($refs.modal)
          }"
        >
          삭제하기
          <div class="ui-border-line"></div>
        </li>
        <li class="text-center" @click="() => $refs.modal.hide()">취소</li>
      </ul>
    </modal>
    <page-modal
      ref="pageModal"
      :title="'이미지 등록 가이드'"
      :class="'close-right'"
      >
      <div slot="body" class="page-modal-body">
        <div class="guideLetter">
          <icon src="img/my-profile/megaphone.png" width="1.8rem" height="1.8rem" :cover="false" />
          개인 셀카, 저화질의 사진 등은 등록이 불가해요!
        </div>
        <img
          slot="body"
          :src="$lib.imgUrl('img/my-profile/my-profile-picture-information02.png', true)"
          alt="image"
          :style="{
            margin: 'auto',
            display: 'block',
          }"
          >
        <div class="guide-list-wrap">
          <div class="guide-list-tit">꼭 읽어보세요!</div>
          <ul class="guide-list">
            <li>스튜디오 프로필 사진 또는 포트폴리오 사진 (최소 3장이상) 등록</li>
            <li>공연, 행사 모습 등 전문성이 보이는 사진</li>
            <li>얼굴 식별이 가능한 사진 최소 1장 이상 포함</li>
            <li class="emphasize">이 외 캡쳐 사진 및 전문성과 무관한 사진 등록 불가</li>
          </ul>
        </div>
      </div>
    </page-modal>
  </div>
</template>
<script>
import Icon from '@/components/common/Icon'
import CheckBox from '@/components/common/CheckBox'
import Modal from '@/components/common/Modal'
import Draggable from 'vuedraggable'
import PageModal from '@/components/common/PageModal'
import PageHeader from '@/components/common/PageHeader'

export default {
  components: {
    PageHeader,
    Icon,
    CheckBox,
    Modal,
    Draggable,
    PageModal,
  },
  props: {
    artistData: {
      type: Object,
      required: true,
    },
  },
  data() {
    const artistData = this.artistData

    let listData = []

    for (let key in artistData) {
      let val = artistData[key]
      if (/^imgUrl[0-9]+$/.test(key)) {
        if (val) {
          listData.push(val)
        }
      }
    }

    return {
      listData,
      modalData: {
        key: null,
        action: null,
      },
      validate: {
        nav : 2,
        sub : 0,
        isValid: false,
      },
    }
  },
  watch: {
    listData: {
      handler(newValue, oldValue) {
        // 전체 formData를 감시하여 특정 로직을 수행합니다.
        let formData = this.setFormData()
        this.$emit('update-data', formData)
        this.checkValid()
      },
      deep: true // 객체 내부의 속성까지 감시
    }
  },
  created() {
    this.$store.commit('setGnbRightBtnCb', this.gnbRightBtnCb)
    this.checkValid()
  },
  mounted() {
    this.$root.$on('img-uploader:response', (uid, success, url) => {
      if (this._uid === uid && success) {
        this.injectArtistImgUrl(url)
      }
    })
  },
  methods: {
    openActionModal(key) {
      if (key >= 0) {
        this.modalData.key = key
        this.$refs.modal.show()
      } else {
        this.modalData.key = this.listData.length
        this.modalData.action = 'post'
        this.confirmActionModal(this.$refs.modal)
      }
    },
    confirmActionModal(modal) {
      this.nowKey = this.modalData.key
      if (this.modalData.action === 'post') {
        this.$root.$emit('img-uploader:request', this._uid, {
          cropperOption: {
            aspectRatio: 30 / 25,
          },
        })
      } else if (this.modalData.action === 'delete') {
        this.removeData(this.nowKey)
      }
      modal?.hide?.()
    },
    removeData(key) {
      this.listData.splice(key, 1)
      this.nowKey = null
    },
    injectArtistImgUrl(url) {
      this.listData.splice(this.nowKey, 1, url)
      this.nowKey = null
      this.$forceUpdate()
      return true
    },
    setFormData() {
      let formData = {}
      for (let key = 0; key < 10; key++) {
        formData['imgUrl' + (key + 1)] = this.listData[key] || ''
      }
      return formData
    },
    checkValid(){
      let isValid = this.listData.length >= 3
      this.validate.isValid = isValid;

      this.$emit('validate', this.validate)
    }
  },
}
</script>


<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

#page > .container {
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-top: 0.4rem;
  margin-bottom: 9rem;  
  h4 {
    font-weight: 400;
    font-size: 2rem !important;
  }

  .my-profile-wrap {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    // padding: 0 3rem;
    padding: 0 !important;
  }
  .guide-btn {
    margin: 0.3rem 0 6rem;
    font-size: 2rem;
    font-weight: 600;
    color: #000;
  }

  .image-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
    margin-bottom: 6rem;
    .image-item {
      overflow: hidden;
      position: relative;
      width: calc(50% - 0.8rem);
      height: 17.2rem;
      border-radius: 1.2rem;
      cursor: pointer;

      &.image-add-btn {
        border: 0.2rem dashed #ccc;
        border-radius: 1.2rem;
        background-color: #f8f8f8;
        .ui-inputbox {
          &.btn-add {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            .icon {
              height: 100%;
            }
          }
        }
      }

      .ui-bg-img {
        height: 100%;
        border-radius: 0.5rem;
        background-color: #efefef;
      }

      .ui-icon-menu {
        display: none;
        margin: 6rem 0;
      }

      .item-handler {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(0,0,0,0.6);
        cursor: pointer;
      }

      .item-delete {
        position: absolute;
        top: 1.3rem;
        right: 1.3rem;
      }

      &.sortable-drag {
        background-color: #fefefe;
        box-shadow: 0 0.5rem 2rem 0 rgba($color: #000000, $alpha: 0.2);
      }

      .artist-badge {
        position: absolute;
        top: 1.3rem;
        left: 1.3rem;
        overflow: hidden;

        > div {
          display: inline-block;
          width: auto;
          padding: 0.4rem 0.8rem;
          border-radius: 0.6rem;
          background-color: #6433F0;
          color: #fff;
          font-size: 1.4rem;
          font-weight: 600;
          text-align: center;
        }
      }
    }
  }

  textarea {
    line-height: 3rem;
    padding: 3rem 0;
    padding: 3rem 0;
    border: 0;
    width: 100%;
    min-height: 20rem;
    font-size: 1.6rem;
  }

  .page-modal-body {
    margin: 0;
    margin-top: -4rem;
    .guideLetter {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 6rem;
      padding: 1.2rem 2rem;
      border-radius: 0.8rem;
      background-color: rgba(#AB54BB,0.1);
      font-size: 1.6rem;
      line-height: 2.1rem;
      color: #AB54BB;
    }
    .guide-list-wrap {
      margin-top: 4rem;
      .guide-list-tit {
        font-size: 2.6rem;
        font-weight: 600;
      }
      .guide-list {
        margin-top: 0.8rem;
        li {
          margin-left: 2rem;
          font-size: 1.9rem;
          color: #666;
          list-style: disc;
          &.emphasize {
            color: #F33841;
          }
          & ~ li {
            margin-top: 0.8rem;
          }
        }
      }
    }
  }
}
</style>