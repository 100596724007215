var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      ref: "YoutubeGuideModal",
      attrs: { useFooter: false, maxWidth: "44rem", bodyMaxHeight: "none" }
    },
    [
      this.genre == "sing"
        ? _c("template", { slot: "body" }, [
            _c("div", { staticClass: "guide-modal-header" }, [
              _c("div", { staticClass: "guide-tit" }, [
                _vm._v("가수, 보컬 분야")
              ]),
              _c("div", { staticClass: "guide-txt" }, [
                _vm._v(" 노래 실력을 확인 할 수 있는"),
                _c("br"),
                _vm._v(" 영상 최소 5개 이상 등록 ")
              ]),
              _c("div", { staticClass: "notice" }, [
                _vm._v("• 행사, 축가 영상 3개 이상 필수")
              ])
            ]),
            _c("div", { staticClass: "guide-modal-content" }, [
              _c("div", { staticClass: "guide-list-wrap" }, [
                _c("div", { staticClass: "guide-badge" }, [_vm._v("주의")]),
                _c("ul", { staticClass: "guide-list" }, [
                  _c("li", [
                    _vm._v(
                      " 개인 커버, M/V, 자체 버스킹 등은 행사로 인정되지 않음 "
                    ),
                    _c("ul", { staticClass: "second-list" }, [
                      _c("li", [_vm._v("2분 이상의 영상만 등록 가능")])
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "guide-list-wrap" }, [
                _c("div", { staticClass: "guide-badge" }, [_vm._v("등록불가")]),
                _c("ul", { staticClass: "guide-list" }, [
                  _c("li", [
                    _vm._v(" 노래방, 집 등의 장소에서 촬영한 영상 "),
                    _c("ul", { staticClass: "second-list" }, [
                      _c("li", [_vm._v("개인 녹음실은 가능")])
                    ])
                  ]),
                  _c("li", [_vm._v("본인 등록 분야와 무관한 영상")])
                ])
              ])
            ]),
            _c("div", { staticClass: "guide-modal-footer" }, [
              _c("div", { staticClass: "ui-border-line" }),
              _c(
                "button",
                {
                  staticClass: "text-center on",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      return _vm.hide()
                    }
                  }
                },
                [_c("span", [_vm._v("닫기")])]
              )
            ])
          ])
        : this.genre == "mc"
        ? _c("template", { slot: "body" }, [
            _c("div", { staticClass: "guide-modal-header" }, [
              _c("div", { staticClass: "guide-tit" }, [_vm._v("사회자 분야")]),
              _c("div", { staticClass: "guide-txt" }, [
                _vm._v(" 진행 실력을 확인할 수 있는 오프라인 행사"),
                _c("br"),
                _vm._v(" 영상 5개 이상 등록 ")
              ]),
              _c("div", { staticClass: "notice" }, [
                _vm._v("• EX : 오프라인 기업행사, 결혼식 등")
              ])
            ]),
            _c("div", { staticClass: "guide-modal-content" }, [
              _c("div", { staticClass: "guide-list-wrap" }, [
                _c("div", { staticClass: "guide-badge" }, [_vm._v("주의")]),
                _c("ul", { staticClass: "guide-list" }, [
                  _c("li", [_vm._v("3분 이상의 영상만 등록 가능")]),
                  _c("li", [
                    _vm._v(
                      "방송/TV 진행 영상은 등록 가능하나, 오프라인 행사로 인정되지 않음"
                    )
                  ]),
                  _c("li", [
                    _vm._v("동일 행사의 영상을 나눠서 등록할 경우 1개로 인정")
                  ])
                ])
              ]),
              _c("div", { staticClass: "guide-list-wrap" }, [
                _c("div", { staticClass: "guide-badge" }, [_vm._v("등록불가")]),
                _c("ul", { staticClass: "guide-list" }, [
                  _c("li", [_vm._v("본인 등록 분야와 무관한 영상")]),
                  _c("li", [_vm._v("실제 진행하는 모습이 1분 이하인 영상")]),
                  _c("li", [
                    _vm._v("1시간 이상의 전체 행사 영상 (하이라이트 편집 필요)")
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "guide-modal-footer" }, [
              _c("div", { staticClass: "ui-border-line" }),
              _c(
                "button",
                {
                  staticClass: "text-center on",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      return _vm.hide()
                    }
                  }
                },
                [_c("span", [_vm._v("닫기")])]
              )
            ])
          ])
        : this.genre == "model"
        ? _c("template", { slot: "body" }, [
            _c("div", { staticClass: "guide-modal-header" }, [
              _c("div", { staticClass: "guide-tit" }, [_vm._v("모델 분야")]),
              _c("div", { staticClass: "guide-txt" }, [
                _vm._v(" 출연 영상, 스케치 영상, "),
                _c("br"),
                _vm._v(" 본인 소개 영상 등 (선택사항) ")
              ])
            ]),
            _c("div", { staticClass: "guide-modal-content" }, [
              _c("div", { staticClass: "guide-list-wrap" }, [
                _c("div", { staticClass: "guide-badge" }, [_vm._v("등록불가")]),
                _c("ul", { staticClass: "guide-list" }, [
                  _c("li", [_vm._v("본인 등록 분야와 무관한 영상")])
                ])
              ])
            ]),
            _c("div", { staticClass: "guide-modal-footer" }, [
              _c("div", { staticClass: "ui-border-line" }),
              _c(
                "button",
                {
                  staticClass: "text-center on",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      return _vm.hide()
                    }
                  }
                },
                [_c("span", [_vm._v("닫기")])]
              )
            ])
          ])
        : this.genre == "etc"
        ? _c("template", { slot: "body" }, [
            _c("div", { staticClass: "guide-modal-header" }, [
              _c("div", { staticClass: "guide-tit" }, [
                _vm._v("연주, 댄스, DJ, 퍼포먼스 분야")
              ]),
              _c("div", { staticClass: "guide-txt" }, [
                _vm._v(" 본인 실력을 확인할 수 있는 오프라인 공연, 행사"),
                _c("br"),
                _vm._v(" 영상 최소 5개 이상 등록 ")
              ])
            ]),
            _c("div", { staticClass: "guide-modal-content" }, [
              _c("div", { staticClass: "guide-list-wrap" }, [
                _c("div", { staticClass: "guide-badge" }, [_vm._v("주의")]),
                _c("ul", { staticClass: "guide-list" }, [
                  _c("li", [
                    _vm._v(
                      "개인 연습, 커버, M/V 등 등록 가능하나 필수 개수에 반영되지 않음"
                    )
                  ]),
                  _c("li", [_vm._v("2분 이상의 영상만 등록 가능")])
                ])
              ]),
              _c("div", { staticClass: "guide-list-wrap" }, [
                _c("div", { staticClass: "guide-badge" }, [_vm._v("등록불가")]),
                _c("ul", { staticClass: "guide-list" }, [
                  _c("li", [_vm._v("본인 등록 분야와 무관한 영상")])
                ])
              ])
            ]),
            _c("div", { staticClass: "guide-modal-footer" }, [
              _c("div", { staticClass: "ui-border-line" }),
              _c(
                "button",
                {
                  staticClass: "text-center on",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      return _vm.hide()
                    }
                  }
                },
                [_c("span", [_vm._v("닫기")])]
              )
            ])
          ])
        : this.genre == "host"
        ? _c("template", { slot: "body" }, [
            _c("div", { staticClass: "guide-modal-header" }, [
              _c("div", { staticClass: "guide-tit" }, [
                _vm._v("쇼호스트 분야")
              ]),
              _c("div", { staticClass: "guide-txt" }, [
                _vm._v(" 네이버 쇼핑 라이브 외에 행사 영상 등록 ")
              ])
            ]),
            _c("div", { staticClass: "guide-modal-content" }, [
              _c("div", { staticClass: "guide-list-wrap" }, [
                _c("div", { staticClass: "guide-badge" }, [_vm._v("주의")]),
                _c("ul", { staticClass: "guide-list" }, [
                  _c("li", [
                    _vm._v(
                      "기업행사, 결혼식 등의 활동도 할 경우 5개 이상 필수 등록"
                    )
                  ]),
                  _c("li", [_vm._v("3분 이상의 영상만 등록 가능")])
                ])
              ]),
              _c("div", { staticClass: "guide-list-wrap" }, [
                _c("div", { staticClass: "guide-badge" }, [_vm._v("등록불가")]),
                _c("ul", { staticClass: "guide-list" }, [
                  _c("li", [_vm._v("본인 등록 분야와 무관한 영상")]),
                  _c("li", [_vm._v("실제 진행하는 모습이 1분 이하인 영상")]),
                  _c("li", [
                    _vm._v("1시간 이상의 전체 행사 영상 (하이라이트 편집 필요)")
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "guide-modal-footer" }, [
              _c("div", { staticClass: "ui-border-line" }),
              _c(
                "button",
                {
                  staticClass: "text-center on",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      return _vm.hide()
                    }
                  }
                },
                [_c("span", [_vm._v("닫기")])]
              )
            ])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }