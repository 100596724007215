var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "my-profile-wrap" },
        [
          _c(
            "page-header",
            {
              attrs: {
                title: "네이버 쇼핑 라이브\n영상을 등록해주세요. ",
                "title-small": "(" + _vm.formData.liveMov.length + "/10)"
              }
            },
            [
              _c("template", { slot: "subtitle" }, [
                _vm._v(" 네이버 영상 링크와 썸네일을 함께 등록하세요. "),
                _c("span", { staticClass: "red-font" }, [_vm._v("(최소 5개)")]),
                _c("br"),
                _vm._v(" 재생이 되고 있는 라이브 영상 링크를 등록해주세요!"),
                _c("br"),
                _c("span", { staticClass: "grey-font" }, [
                  _vm._v(
                    "(‘라이브가 종료되었습니다.’ 화면이 나오는 링크는 등록 불가)"
                  )
                ])
              ])
            ],
            2
          ),
          _c(
            "button",
            {
              staticClass: "guide-btn",
              attrs: { type: "button" },
              on: {
                click: function() {
                  return _vm.$refs.pageModal.show()
                }
              }
            },
            [_vm._v("썸네일 가이드 보기 >")]
          ),
          _c(
            "div",
            { staticClass: "media-list" },
            [
              _vm.formData.liveMov.length < 10
                ? _c("div", { staticClass: "media-item media-add-btn" }, [
                    _c(
                      "div",
                      {
                        staticClass: "ui-inputbox ui-btn btn-add",
                        on: {
                          click: function() {
                            return _vm.openNaverModal()
                          }
                        }
                      },
                      [
                        _c("icon", {
                          attrs: {
                            src: "img/common/plus-gray.png",
                            width: "2.4rem",
                            height: "2.4rem",
                            cover: false
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._l(_vm.formData.liveMov, function(row, key) {
                return _c("div", { key: key, staticClass: "media-item" }, [
                  _c("div", { staticClass: "ui-inputbox" }, [
                    _c("div", { staticClass: "inputbox--form" }, [
                      _c(
                        "div",
                        { staticClass: "form--body" },
                        [
                          row.image
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass: "form--body--thumbnail",
                                    on: {
                                      click: function() {
                                        if (row.image) {
                                          _vm.openManageModal(key)
                                          return
                                        }
                                        _vm.selelctLiveMovImage(key)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: { src: _vm.$lib.cdnUrl(row.image) }
                                    })
                                  ]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ])
                  ]),
                  _c(
                    "button",
                    { staticClass: "item-delete", attrs: { type: "button" } },
                    [
                      _c("icon", {
                        attrs: {
                          src: "img/common/close.png",
                          width: "2.8rem",
                          height: "2.8rem",
                          cover: false
                        },
                        on: {
                          click: function() {
                            return _vm.removeFormData(key)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-handler",
                      on: {
                        click: function() {
                          return _vm.openManageModal(key)
                        }
                      }
                    },
                    [
                      _c("icon", {
                        attrs: {
                          src: "img/my-profile/edit.png",
                          width: "3.8rem",
                          height: "3.8rem",
                          cover: false
                        }
                      })
                    ],
                    1
                  )
                ])
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "page-modal",
        {
          ref: "pageModal",
          class: "close-right",
          attrs: { title: "썸네일 가이드" }
        },
        [
          _c(
            "div",
            {
              staticClass: "page-modal-body",
              attrs: { slot: "body" },
              slot: "body"
            },
            [
              _c(
                "div",
                { staticClass: "guideLetter" },
                [
                  _c("icon", {
                    attrs: {
                      src: "img/my-profile/megaphone.png",
                      width: "1.8rem",
                      height: "1.8rem",
                      cover: false
                    }
                  }),
                  _vm._v(" 따로 만들 필요 없어요!"),
                  _c("br"),
                  _vm._v(" 아래 내용에 맞는 방송 장면을 캡쳐해서 사용하세요. ")
                ],
                1
              ),
              _c("img", {
                style: {
                  margin: "auto",
                  display: "block"
                },
                attrs: {
                  slot: "body",
                  src: _vm.$lib.imgUrl(
                    "img/my-profile/my-profile-naver-live-information02.png",
                    true
                  ),
                  alt: "image"
                },
                slot: "body"
              }),
              _c("div", { staticClass: "guide-list-wrap" }, [
                _c("div", { staticClass: "guide-list-tit" }, [
                  _vm._v("꼭 읽어보세요!")
                ]),
                _c("ul", { staticClass: "guide-list" }, [
                  _c("li", [_vm._v("판매하는 상품이 잘 보여야 해요.")]),
                  _c("li", [_vm._v("본인 얼굴이 나와야 해요.")]),
                  _c("li", [
                    _vm._v("댓글이나 하트가 많이 보이면 정말 좋아요.")
                  ]),
                  _c("li", { staticClass: "emphasize" }, [
                    _vm._v("상품이 안보이는 썸네일 등록 불가")
                  ])
                ])
              ])
            ]
          )
        ]
      ),
      _c(
        "modal",
        {
          ref: "naverModal",
          attrs: {
            title: "네이버 쇼핑 라이브 등록",
            confirm: "등록",
            confirmCb: _vm.confirmNaverModal,
            hiddenCb: _vm.clearNaverModal,
            useCancel: false,
            noBorder: "",
            roundModal: ""
          }
        },
        [
          _c("template", { slot: "body" }, [
            _c(
              "div",
              {
                staticClass: "form--body--thumbnail",
                on: {
                  click: function() {
                    return _vm.selelctLiveMovImage(_vm.state.index)
                  }
                }
              },
              [
                _vm.naverModal.imageUrl
                  ? [
                      _c("img", {
                        attrs: { src: _vm.$lib.cdnUrl(_vm.naverModal.imageUrl) }
                      })
                    ]
                  : [
                      _c("div", { staticClass: "thumbnail-txt" }, [
                        _vm._v("썸네일 등록")
                      ]),
                      _c("icon", {
                        attrs: {
                          src: "img/common/plus-gray.png",
                          width: "2rem",
                          height: "2rem",
                          cover: false
                        }
                      })
                    ]
              ],
              2
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.naverModal.url,
                  expression: "naverModal.url"
                }
              ],
              attrs: { type: "text", placeholder: "url을 입력하세요" },
              domProps: { value: _vm.naverModal.url },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.naverModal, "url", $event.target.value)
                }
              }
            }),
            !_vm.isValidUrl
              ? _c("p", { staticClass: "input-notice" }, [
                  _vm._v("※ 네이버 쇼핑라이브 URL만 등록 가능합니다.")
                ])
              : _vm._e()
          ])
        ],
        2
      ),
      _c(
        "modal",
        {
          ref: "manageModal",
          attrs: { "hidden-cb": _vm.clearManageModal, "use-footer": false }
        },
        [
          _c("ul", { attrs: { slot: "body" }, slot: "body" }, [
            _c(
              "li",
              {
                staticClass: "text-center",
                on: {
                  click: function() {
                    _vm.openNaverModal(_vm.state.index)
                    _vm.$refs.manageModal.hide()
                  }
                }
              },
              [
                _vm._v(" 쇼핑라이브 수정하기 "),
                _c("div", { staticClass: "ui-border-line" })
              ]
            ),
            _c(
              "li",
              {
                staticClass: "text-center",
                on: {
                  click: function() {
                    _vm.removeFormData(_vm.state.index)
                    _vm.$refs.manageModal.hide()
                  }
                }
              },
              [
                _vm._v(" 삭제하기 "),
                _c("div", { staticClass: "ui-border-line" })
              ]
            ),
            _c(
              "li",
              {
                staticClass: "text-center on",
                on: {
                  click: function() {
                    return _vm.$refs.manageModal.hide()
                  }
                }
              },
              [_vm._v("취소")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }