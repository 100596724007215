var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-profile-wrap" },
    [
      _vm.$lib.validMultipleGenre(this.artistData.genre, "모델")
        ? _c(
            "page-header",
            {
              attrs: {
                title:
                  "다양한 컨셉의 모델 사진을\n등록해주세요. (" +
                  _vm.listData.length +
                  "/10)"
              }
            },
            [
              _c("template", { slot: "subtitle" }, [
                _vm._v(" 표정, 의상, 분위기 등이 다양할수록 좋아요! "),
                _c("span", { staticClass: "red-font" }, [_vm._v("(최소 3개)")])
              ])
            ],
            2
          )
        : _c(
            "page-header",
            {
              attrs: {
                title: "전문성이 돋보이는\n고화질 사진을 등록하세요. ",
                "title-small": "(" + _vm.listData.length + "/10)"
              }
            },
            [
              _c("template", { slot: "subtitle" }, [
                _vm._v(" 첫번째 사진은 섭외에 아주 큰 영향을 끼쳐요!"),
                _c("br"),
                _vm._v("고화질의 프로필 사진을 등록하세요. "),
                _c("span", { staticClass: "red-font" }, [_vm._v("(최소 3개)")])
              ])
            ],
            2
          ),
      _c(
        "button",
        {
          staticClass: "guide-btn",
          attrs: { type: "button" },
          on: {
            click: function() {
              return _vm.$refs.pageModal.show()
            }
          }
        },
        [_vm._v("반응 좋은 사진 고르는 방법 보기 >")]
      ),
      _c(
        "draggable",
        {
          staticClass: "image-list",
          attrs: {
            options: {
              draggable: ".image-item",
              handle: ".ui-icon-menu",
              disabled: true // 비활성화
            }
          },
          model: {
            value: _vm.listData,
            callback: function($$v) {
              _vm.listData = $$v
            },
            expression: "listData"
          }
        },
        [
          _vm.listData.length < 10
            ? _c("div", { staticClass: "image-item image-add-btn" }, [
                _c(
                  "div",
                  {
                    staticClass: "ui-inputbox ui-btn btn-add",
                    on: {
                      click: function() {
                        return _vm.openActionModal()
                      }
                    }
                  },
                  [
                    _c("icon", {
                      attrs: {
                        src: "img/common/plus-gray.png",
                        width: "2.4rem",
                        height: "2.4rem",
                        cover: false
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._l(_vm.listData, function(row, key) {
            return _c("div", { key: key, staticClass: "image-item" }, [
              _c("div", {
                staticClass: "ui-bg-img",
                style: {
                  backgroundImage: row
                    ? "url(" + _vm.$lib.cdnUrl(row) + ")"
                    : null
                }
              }),
              key == 0
                ? _c("div", { staticClass: "artist-badge" }, [
                    _c("div", [_vm._v("대표")])
                  ])
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "item-delete",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      return _vm.removeData(key)
                    }
                  }
                },
                [
                  _c("icon", {
                    attrs: {
                      src: "img/common/close.png",
                      width: "2.8rem",
                      height: "2.8rem",
                      cover: false
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "item-handler",
                  on: {
                    click: function() {
                      return _vm.openActionModal(key)
                    }
                  }
                },
                [
                  _c("icon", {
                    attrs: {
                      src: "img/my-profile/edit.png",
                      width: "3.8rem",
                      height: "3.8rem",
                      cover: false
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "ui-icon-menu" }, [
                _c("div", { staticClass: "ui-icon-menu-bar" }),
                _c("div", { staticClass: "ui-icon-menu-bar" }),
                _c("div", { staticClass: "ui-icon-menu-bar" })
              ])
            ])
          })
        ],
        2
      ),
      _c(
        "modal",
        {
          ref: "modal",
          attrs: {
            "hidden-cb": function() {
              _vm.modalData.key = null
              _vm.modalData.action = null
            },
            "use-footer": false
          }
        },
        [
          _c("ul", { attrs: { slot: "body" }, slot: "body" }, [
            _c(
              "li",
              {
                staticClass: "text-center",
                class: { on: _vm.modalData.action === "post" },
                on: {
                  click: function() {
                    _vm.modalData.action = "post"
                    _vm.confirmActionModal(_vm.$refs.modal)
                  }
                }
              },
              [
                _vm._v(" 사진 선택하기 "),
                _c("div", { staticClass: "ui-border-line" })
              ]
            ),
            _c(
              "li",
              {
                staticClass: "text-center",
                class: { on: _vm.modalData.action === "delete" },
                on: {
                  click: function() {
                    _vm.modalData.action = "delete"
                    _vm.confirmActionModal(_vm.$refs.modal)
                  }
                }
              },
              [
                _vm._v(" 삭제하기 "),
                _c("div", { staticClass: "ui-border-line" })
              ]
            ),
            _c(
              "li",
              {
                staticClass: "text-center",
                on: {
                  click: function() {
                    return _vm.$refs.modal.hide()
                  }
                }
              },
              [_vm._v("취소")]
            )
          ])
        ]
      ),
      _c(
        "page-modal",
        {
          ref: "pageModal",
          class: "close-right",
          attrs: { title: "이미지 등록 가이드" }
        },
        [
          _c(
            "div",
            {
              staticClass: "page-modal-body",
              attrs: { slot: "body" },
              slot: "body"
            },
            [
              _c(
                "div",
                { staticClass: "guideLetter" },
                [
                  _c("icon", {
                    attrs: {
                      src: "img/my-profile/megaphone.png",
                      width: "1.8rem",
                      height: "1.8rem",
                      cover: false
                    }
                  }),
                  _vm._v(" 개인 셀카, 저화질의 사진 등은 등록이 불가해요! ")
                ],
                1
              ),
              _c("img", {
                style: {
                  margin: "auto",
                  display: "block"
                },
                attrs: {
                  slot: "body",
                  src: _vm.$lib.imgUrl(
                    "img/my-profile/my-profile-picture-information02.png",
                    true
                  ),
                  alt: "image"
                },
                slot: "body"
              }),
              _c("div", { staticClass: "guide-list-wrap" }, [
                _c("div", { staticClass: "guide-list-tit" }, [
                  _vm._v("꼭 읽어보세요!")
                ]),
                _c("ul", { staticClass: "guide-list" }, [
                  _c("li", [
                    _vm._v(
                      "스튜디오 프로필 사진 또는 포트폴리오 사진 (최소 3장이상) 등록"
                    )
                  ]),
                  _c("li", [_vm._v("공연, 행사 모습 등 전문성이 보이는 사진")]),
                  _c("li", [
                    _vm._v("얼굴 식별이 가능한 사진 최소 1장 이상 포함")
                  ]),
                  _c("li", { staticClass: "emphasize" }, [
                    _vm._v("이 외 캡쳐 사진 및 전문성과 무관한 사진 등록 불가")
                  ])
                ])
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }