<template>
  <div class="my-profile-wrap">
    <div class="ui-border-line ui-h-0 ui-mb-2" />

    <div class="form-wrap">
      <h4 :style="{fontWeight: 500}">전문분야<span class="essential">*</span></h4>
      <div class="guideLetter">
        선택 Tip<br />
        마술, 퓨전국악, 뮤지컬 등은  ‘퍼포먼스'를 선택
      </div>
      <tag-box>
        <li
          v-for="(row, key) in genres"
          :key="key"
          :class="{ on: !!formData.genre && formData.genre == row }"
          @click="() => { setGenre(row) }"
        >
          {{ $lib.t_genre(row) }}
        </li>
        <li
          @click="() => $refs.etcModal.show()"
          :class="{
            on: isEtc,
          }"
        >
        {{this.etcData.genreEtc ? this.etcData.genreEtc : '직접입력'}}
        </li>
      </tag-box>
    </div>

    <div class="form-wrap" v-if="formData.genre == 'MC'">
      <div class="profile-tooltip">
        <h4 :style="{fontWeight: 500}">
          그룹 선택 (복수 선택)
          <span class="essential">*</span>
          <icon
            class="profile-tooltip-btn ui-btn"
            src="img/common/tooltip.png"
            width="2.2rem"
            height="2.2rem"
            :cover="false"
            @click="() => showTooltip('profile')"
          />
          <div
            class="profile-tooltip-toast toasted primary default"
            :class="{ show: tooltip.profile }"
          >
            사회자 분야에서 어느 직군에 속해있는지, 전문분야가 무엇인지 보여줄 수 있어 의뢰자 분들이 전문가님을 더욱 쉽고 빠르게 찾을 수 있어요.
          </div>
        </h4>
      </div>
      <tag-box>
        <li
          v-for="(row, key) in genreTypes"
          :key="key"
          :class="{ on: inputData.genreType[row] }"
          @click="() => {
            setGenreType(row)
          }"
        >
          {{ row }}
        </li>
        <li
          @click="() => $refs.etcMcGroupModal.show()"
          class="ui-btn"
        >
          직접입력
        </li>
      </tag-box>
    </div>

    <div class="form-wrap" v-if="formData.genre == '쇼호스트'">
      <h4 :style="{fontWeight: 500}">진행 가능한 쇼호스트 분야 (복수 선택)<span class="essential">*</span></h4>
      <tag-box>
        <li
          v-for="(row, key) in (this.labels.filter(row => row != '연예인'))"
          :key="key"
          :class="{ on: inputData.showHost[key] }"
          @click="() => { setShowhost(key) }"
        >
          {{ row }}
        </li>
      </tag-box>
    </div>
    <div class="form-wrap" v-if="subGenreList && subGenreList.length>0">
      <h4 :style="{fontWeight: 500}">자신 있는 장르 (복수 선택)<span class="essential">*</span></h4>
      <tag-box>
        <li
          v-for="(row, key) in subGenreList.filter((row) => row != '기타')"
          :key="key"
          :class="{ on: formData.subGenre ? formData.subGenre.split(',').includes(row) : null }"
          @click="() => { setSubGenreText(row, 'default') }"
        >
          {{ row }}
        </li>
        <li
          @click="() => $refs.etcSubGenreModal.show()"
          :class="{
            on: isEtc,
          }"
        >
          직접입력
        </li>
      </tag-box>
    </div>

    <div class="form-wrap">
      <h4 :style="{fontWeight: 500}">경력<span class="essential">*</span></h4>
      <check-box
        :text="formData.career > 0 ? $config.constant.career[formData.career] : '해당 분야의 경력을 선택해주세요.'"
        :click="() => {
          modalData.career = formData.career
          $refs.CareerModal.show()
        }"
        :on="formData.career > 0"
        :iconPos="'right'"
        select
      >
      </check-box>

      <modal
        ref="CareerModal"
        title="경력을 선택해주세요."
        :confirmCb="modal => {
          formData.career = modalData.career;
          modal.hide();
        }"
        :hiddenCb="() => {}"
      >
        <ul slot="body">
          <li
            class="text-center"
            v-for="(row, key) in $config.constant.career" :key="key"
            @click="() => modalData.career = Number(key)"
            :class="{ on: modalData.career === Number(key) }"
          >
            <template v-if="key > 0">
              {{row}}
              <!-- <icon class="icon" :src="`img/common/check${modalData.career === Number(key) ? '-on' : ''}.png`" width="2rem" height="6rem" :cover="false" /> -->
              <div class="ui-border-line"></div>
            </template>
          </li>
        </ul>
      </modal>
    </div>

    <div class="form-wrap" v-if="formData.genre == '노래'">
      <h4 :style="{fontWeight: 500}">팀 종류 (복수 선택)<span class="essential">*</span></h4>
      <tag-box>
        <li
          v-for="(row, key) in this.$config.constant.teamType"
          :key="key"
          :class="{ on: inputData.teamType.indexOf(key) !== -1 }"
          @click="() => { let idx = inputData.teamType.indexOf(key)
            if (idx === -1) {
              inputData.teamType.push(key)
            } else {
              inputData.teamType.splice(idx, 1)
            }
            formData.teamType = inputData.teamType.map(type => type.trim()).join(',');
          }"
        >
          {{ row }}
        </li>
      </tag-box>
    </div>

    <div class="form-wrap" v-if="formData.genre == '노래'">
      <h4 :style="{fontWeight: 500}">자신 있는 노래<span class="essential">*</span></h4>
      <div class="input-help">가수명-노래제목의 형식으로 등록해주세요.</div>

      <div class="ui-inputbox has-btn">
        <input type="text" v-model="song" placeholder="예) 아이유-금요일에 만나요">
        <button
          type="button"
          class="add-btn"
          @click="addRecommSong()"
        >
        추가
        </button>
      </div>

      <ul class="added-list" v-if="inputData.recommSong && inputData.recommSong!=''">
        <div v-for="(row, key) in inputData.recommSong" :key="key">
          <li>
            <span>{{row}}</span>
            <div class="ui-btn" @click="deleteData('recommSong',key)">
              <icon src="img/common/close-gray-button.png" width="1.6rem" height="1.6rem" :cover="false" />
            </div>
          </li>
        </div>
      </ul>
    </div>

    <div class="form-wrap" v-if="formData.genre == '노래'">
      <h4 :style="{fontWeight: 500}">닮은 목소리의 연예인 이름<span class="essential">*</span></h4>
      <div class="ui-inputbox has-btn">
        <input type="text" v-model="voice" placeholder="태연, 아리아나 그란데">
        <button
          type="button"
          class="add-btn"
          @click="addMatchVoice()"
        >
        추가
        </button>
      </div>

      <ul class="added-list" v-if="inputData.matchVoice && inputData.matchVoice!=''">
        <div v-for="(row, key) in inputData.matchVoice" :key="key">
          <li>
            <span>{{row}}</span>
            <div class="ui-btn" @click="deleteData('matchVoice',key)">
              <icon src="img/common/close-gray-button.png" width="1.6rem" height="1.6rem" :cover="false" />
            </div>
          </li>
        </div>
      </ul>
    </div>

    <div class="form-wrap" v-if="formData.genre == 'MC'">
      <h4 :style="{fontWeight: 500}">진행 스타일 (복수 선택)<span class="essential">*</span></h4>
      <tag-box>
        <li
          v-for="(row, key) in this.$config.constant.mcStyle"
          :key="key"
          :class="{ on: inputData.mcStyle.indexOf(row) !== -1 }"
          @click="() => { let idx = inputData.mcStyle.indexOf(row)
              if (idx === -1) {
                inputData.mcStyle.push(row)
              } else {
                inputData.mcStyle.splice(idx, 1)
              }
              formData.mcStyle = inputData.mcStyle.join('|') || null
            }"
        >
          {{ row }}
        </li>
      </tag-box>
    </div>
    <div v-if="formData.genre == '모델'">
      <div class="form-wrap">
        <h4 :style="{fontWeight: 500}">키<span class="essential">*</span></h4>

        <div class="ui-inputbox">
          <input type="tel" pattern="[0-9]*" inputmode="numeric" v-model="formData.bodyHeight" placeholder="예) 177" @input="onInputCheck($event,'bodyHeight')">
        </div>
      </div>

      <div class="form-wrap">
        <h4 :style="{fontWeight: 500}">몸무게<span class="essential">*</span></h4>

        <div class="ui-inputbox">
          <input type="tel" pattern="[0-9]*" inputmode="numeric" v-model="formData.bodyWeight" placeholder="예) 55" @input="onInputCheck($event,'bodyWeight')">
        </div>
      </div>

      <div class="form-wrap">
        <h4 :style="{fontWeight: 500}">상의 사이즈<span class="essential">*</span></h4>
        <tag-box>
          <li
            v-for="(row, key) in this.$config.constant.bodyTopsize"
            :key="key"
            :class="{ on: formData.bodyTopsize === key }"
            @click="() => { formData.bodyTopsize = key }"
          >
            {{ row }}
          </li>
        </tag-box>
      </div>

      <div class="form-wrap">
        <h4 :style="{fontWeight: 500}">하의 사이즈<span class="essential">*</span></h4>

        <div class="ui-inputbox">
          <input type="tel" pattern="[0-9]*" inputmode="numeric" v-model="formData.bodyBottomsize" placeholder="예) 29" @input="onInputCheck($event,'bodyBottomsize')">
        </div>
      </div>

      <div class="form-wrap">
        <h4 :style="{fontWeight: 500}">신발 사이즈<span class="essential">*</span></h4>

        <div class="ui-inputbox">
          <input type="tel" pattern="[0-9]*" inputmode="numeric"  v-model="formData.bodyShoesize" placeholder="예) 200" @input="onInputCheck($event,'bodyShoesize')">
        </div>
      </div>
    </div>

    <div class="form-wrap">
      <h4 :style="{fontWeight: 500}">출연했던 TV프로그램</h4>

      <div class="ui-inputbox has-btn">
        <input type="text" v-model="program" placeholder="출연 프로그램명">
        <button
          type="button"
          class="add-btn"
          @click="addProgram()"
        >
        추가
        </button>
      </div>

      <ul class="added-list" v-if="inputData.program && inputData.program!=''">
        <div v-for="(row, key) in inputData.program" :key="key">
          <li>
            <span>{{row}}</span>
            <div class="ui-btn" @click="deleteData('program',key)">
            <icon src="img/common/close-gray-button.png" width="1.6rem" height="1.6rem" :cover="false" />
            </div>
          </li>
        </div>
      </ul>
    </div>

    <div class="form-wrap">
      <h4 :style="{fontWeight: 500}">나의 매력 태그<span class="essential">*</span></h4>
      <div class="input-help">장점 성격, 출연 방송 등 자랑하고 싶은 점을 태그로 표현하세요 너무 많으면 안보이니 미리보기로 확인하세요.</div>

      <div class="ui-inputbox typing-count has-btn">
        <input type="text" v-model="hashTag"  placeholder="태그를 입력해주세요" maxlength="10" @input="inputTagHandler" @keyup.enter="keyPress">
        <span class="counting">({{currentLength}}/10)</span>
        <button
          type="button"
          class="add-btn"
          @click="addHashTag()"
        >
        추가
        </button>
      </div>

      <ul class="added-list" v-if="inputData.hashTag && inputData.hashTag!=''">
        <div v-for="(row, key) in inputData.hashTag" :key="key">
          <li>
            <span>{{row}}</span>
            <div class="ui-btn" @click="deleteData('hashTag',key)">
              <icon src="img/common/close-gray-button.png" width="1.6rem" height="1.6rem" :cover="false" />
            </div>
          </li>
        </div>
      </ul>
    </div>

    <modal
      ref="etcModal"
      title="직접 입력해주세요"
      class="etc"
      :showCb="() => etcData.input = etcData.genreEtc"
      confirm="등록"
      :confirmCb="modal => {
        setGenreFromEtc(() => {
          modal.hide()
        })
      }"
    >
      <div slot="body">
        <div class="ui-inputbox etc">
          <input type="text" v-model="etcData.input" placeholder="기타" @input="validateInput"/>
        </div>
      </div>
    </modal>
    <modal
      ref="etcSubGenreModal"
      title="직접 입력해주세요"
      class="etcSubGenre"
      :showCb="() => etcData.input = ''"
      confirm="등록"
      :confirmCb="modal => {
        setSubGenreFromEtc(() => {
          modal.hide()
        });
      }"
    >
      <div slot="body">
        <div class="ui-inputbox etc">
          <input type="text" v-model="etcData.input" placeholder="기타" />
        </div>
      </div>
    </modal>
    <modal
      ref="etcMcGroupModal"
      title="직접 입력해주세요"
      class="etcMC"
      :showCb="() => etcData.genreType = ''"
      confirm="등록"
      :confirmCb="modal => {
        if (!etcData.genreType) return

        genreTypes.push(etcData.genreType)
        $set(inputData.genreType, etcData.genreType, false)
        setGenreType(etcData.genreType)
        modal.hide()
      }"
    >
      <div slot="body">
        <div class="ui-inputbox etc">
          <input type="text" v-model="etcData.genreType" placeholder="기타" />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon'
import TagBox from '@/components/common/TagBox'
import CheckBox from '@/components/common/CheckBox'
import Modal from '@/components/common/Modal'
import router from '@/router'

export default {
  components: {
    Icon,
    TagBox,
    CheckBox,
    Modal,
  },
  props: {
    artistData: {
      type: Object,
      required: true,
    },
  },
  data() {
    const artistData = this.artistData
    const artistGenreTypes = artistData.genreType ? artistData.genreType.split('|') : []
    const genreTypes = Array.from(new Set(this.$config.constant.genreTypes.concat(artistGenreTypes)))
    const genreTypeOptions = genreTypes.reduce((result, row) => {
      let check = false
      if (artistGenreTypes.indexOf(row) !== -1) check = true
      return Object.assign(result, { [row]: check })
    }, {})
    const genreText = (this.$store.state.user?.genreText || [])
          .map(v => v.genre)
          .filter(v => v !== '기타' && v != '행사패키지' && v != '강연자' && v != '연예인' );
    const artistShowHostArr = artistData.showHost ? artistData.showHost.split('|') : []
    const labels = this.$config.constant.showHost
    const showHost = labels.reduce((result, row, key) => {
      let check = false
      if (artistShowHostArr.indexOf(row) !== -1) check = true
      return Object.assign(result, { [key]: check })
    }, {})
    let artistDataSubGenreArr = String(artistData.subGenre || '').split(',') ?? [];

    let artistSubGenre = this.$store.state.user.genreText.find(row => artistData.genre == row.genre)?.subGenreText;
    const subGenreList = artistSubGenre ? this.$store.getters.getEtcGenreText.length > 0 ? Array.from(new Set(artistSubGenre.concat(this.$store.getters.getEtcGenreText))) : Array.from(new Set(artistSubGenre)) : [];

    let etc = artistSubGenre ? artistDataSubGenreArr.filter(row => { return artistSubGenre.indexOf(row) < 0 && row != '' }) : '';
    if(etc != ''){
      this.$store.commit("setEtcGenreText", etc);
    }

    return {
      currentLength : 0,
      artistSubGenre,
      subGenreList,
      labels,
      program: null,
      hashTag: null,
      song: null,
      voice: null,
      formData: {
        artistidx: artistData.artistidx || null,
        genreType: artistData.genreType || null,
        genre: artistData.genre || '',
        career: artistData.career || null,
        showHost: artistData.showHost || null,
        subGenre: artistData.subGenre || null,
        mcStyle: artistData.mcStyle || null,
        program: artistData.program || null,
        hashTag: artistData.hashTag || null,
        teamType: artistData.teamType || null,
        recommSong: artistData.recommSong || null,
        matchVoice: artistData.matchVoice || null,
        bodyHeight: Number(artistData.bodyHeight) || null,
        bodyTopsize: artistData.bodyTopsize || null,
        bodyWeight: Number(artistData.bodyWeight) || null,
        bodyBottomsize: Number(artistData.bodyBottomsize) || null,
        bodyShoesize: Number(artistData.bodyShoesize) || null,
      },
      genreTypes,
      etcData: {
        genreEtc: !genreText.includes(artistData.genre) ? artistData.genre : '',
        input: '',
        subGenre: '',
        genreType: '',
        showHost: '',
      },
      inputData: {
        genreType: Object.assign({}, genreTypeOptions),
        showHost: Object.assign({}, showHost),
        mcStyle: artistData.mcStyle?.split('|').map(v => v.trim()) || [],
        program: artistData.program?.split('|').filter(v => v!==null && v!=undefined && v!='' ) || [],
        hashTag: artistData.hashTag?.split(',').filter(v => v!==null && v!=undefined && v!='' ) || [],
        teamType: artistData.teamType?.trim().split(',') || [],
        recommSong: artistData.recommSong?.split('|').filter(v => v!==null && v!=undefined && v!='' ) || [],
        matchVoice: artistData.matchVoice?.split('|').filter(v => v!==null && v!=undefined && v!='' ) || [],
      },
      genres: genreText,
      tooltip: {
        profile: false,
      },
      modalData: {
        career: artistData.career || null,
      },
      validate: {
        nav : 1,
        isValid: false,
      },
    }
  },
  watch: {
    'formData.genre': function(newValue, oldValue) {
      this.fetchSubGenre(newValue);
    },
    formData: {
      handler(newValue, oldValue) {
        // 전체 formData를 감시하여 특정 로직을 수행합니다.
        this.$emit('update-data', newValue)
        this.checkValid()
      },
      deep: true // 객체 내부의 속성까지 감시
    }
  },
  created() {
    this.checkValid()
  },
  computed: {
    isEtc() {
      return !!this.etcData.genreEtc
    },
  },
  methods: {
    validateInput() {
      // 정규표현식을 사용하여 특수문자 제거
      this.etcData.input = this.etcData.input.replace(/[^\w\dㄱ-ㅎㅏ-ㅣ가-힣]/g, '');
    },
    inputTagHandler(e:any){
      const target = e.target;
      const max = parseInt(target.getAttribute('maxlength'));
      if (target.value.length > max) {
        target.value = target.value.slice(0, max);
      }
      this.currentLength = target.value.length;
    },
    fetchSubGenre(genre) {
      this.artistSubGenre = Array.from(new Set(this.$store.state.user.genreText.find(row => genre == row.genre)?.subGenreText))  || [];
      this.subGenreList = this.artistSubGenre;
      this.genreReset();
    },
    setGenre(genre: string) {
      this.formData.genre = genre
      if(this.etcData.genreEtc != genre){
        this.etcData.genreEtc = ''
      }
    },
    setGenreType(val){
      this.inputData.genreType[val] = !this.inputData.genreType[val]
      this.formData.genreType = this.genreTypes.filter((val) => this.inputData.genreType[val]).join('|') || null
    },
    setGenreFromEtc(resolve: () => void) {
      const input: string = this.etcData.input

      if (input) {
        if (input.includes(',')) {
          this.$toast('분야는 한가지만 등록 가능합니다.')
          return
        }
        this.etcData.genreEtc = input;
      }
      this.setGenre(input)
      resolve()
    },
    setSubGenreFromEtc(resolve: () => void) {
      const arr = []
      const input: string = this.etcData.input
      if (input) {
        arr.push(input)
        this.subGenreList.push(input)
      }
      this.setSubGenreText(arr.join(','), 'etc')
      resolve()
    },
    setShowhost(key){
      this.inputData.showHost[key] = !this.inputData.showHost[key]
      this.formData.showHost = this.labels.filter((row, key) => this.inputData.showHost[key]).join('|') || null
    },
    setSubGenreText(data, type) {
      let subGenre = this.formData.subGenre ? this.formData.subGenre.split(',') : [];
      let etc = this.$store.getters.getEtcGenreText;
      let idx = subGenre.indexOf(data);
      //선택한 데이터가 이미 서브장르에 존재한다면 삭제
      if (idx >= 0) {
        subGenre.splice(idx, 1);
        
        let etcIdx = etc.indexOf(data);
        if(etcIdx >= 0){
          etc.splice(etcIdx, 1);
          this.$store.commit("setEtcGenreText", etc);
        }
      }// 서브장르에 존재하지 않는 데이터라면 신규 추가
      else {
        subGenre.push(data);
        
        if(this.artistSubGenre.indexOf(data) < 0){
          etc.push(data);
          this.etcData.subGenre = etc;
          this.$store.commit("setEtcGenreText", etc);
        }
      }

      if(etc.length != 0 && subGenre.filter(row => row == '기타').length < 1){
        subGenre.push('기타');
      }else if(etc.length == 1 && subGenre.filter(row => row == '기타').length == 1){
        subGenre.splice(subGenre.split(',').indexOf('기타'),1);
      }

      this.formData.subGenre = subGenre.join(',');
    },
    onInputCheck(event, type) {
      let inputValue = event.target.value.replace(/\D/g,'');
      this.formData[type] = inputValue;
    },
    addProgram() {
      let pro = this.program && this.program.trim() ? this.program : null;
      if(pro){
        if (this.inputData.program.length > 0) {
          this.inputData.program[this.inputData.program.length] = this.program
        } else {
          this.inputData.program.push(this.program)
        }
      }
      this.formData.program = this.inputData.program.join('|').replace(/,$/, '')
      this.program = null;
    },
    addHashTag() {
      let tag = this.hashTag && this.hashTag.replaceAll(/#/g,'').trim() ? this.hashTag.replaceAll(/#/g,'') : null;
      if(tag){
        if (this.inputData.hashTag && this.inputData.hashTag.length > 0) {
          this.inputData.hashTag[this.inputData.hashTag.length] = tag
        } else {
          this.inputData.hashTag.push(tag)
        }
      }
      this.formData.hashTag = this.inputData.hashTag.join(',').replace(/,$/, '')
      this.hashTag = null;
    },
    addRecommSong() {
      let song = this.song && this.song.trim() ? this.song: null;
      if(song && this.checkform(song)){
        if (this.inputData.recommSong.length > 0) {
          this.inputData.recommSong[this.inputData.recommSong.length] = song
        } else {
          this.inputData.recommSong.push(song)
        }
      }
      this.formData.recommSong = this.inputData.recommSong.join('|').replace(/,$/, '')
      this.song = null;
    },
    addMatchVoice() {
      let voice = this.voice && this.voice.trim() ? this.voice : null;
      if(voice){
        if (this.inputData.matchVoice.length > 0) {
          this.inputData.matchVoice[this.inputData.matchVoice.length] = voice
        } else {
          this.inputData.matchVoice.push(voice)
        }
      }
      this.formData.matchVoice = this.inputData.matchVoice.join('|').replace(/,$/, '')
      this.voice = null;
    },
    deleteData(type, key){
      switch (type) {
        case 'program' :
          this.inputData.program.splice(key, 1)
          this.formData.program = this.inputData.program.join('|').replace(/,$/, '')
          break;
        case 'hashTag' :
          this.inputData.hashTag.splice(key, 1)
          this.formData.hashTag = this.inputData.hashTag.join(',').replace(/,$/, '')
          break;
        case 'recommSong' :
          this.inputData.recommSong.splice(key, 1)
          this.formData.recommSong = this.inputData.recommSong.join('|').replace(/,$/, '')
          break;
        case 'matchVoice' :
          this.inputData.matchVoice.splice(key, 1)
          this.formData.matchVoice = this.inputData.matchVoice.join('|').replace(/,$/, '')
          break;
      }
    },
    checkform(song) {
      if(!song.includes('-'))  {
        this.$store.commit('setModal', {
          show: true,
          body: '‘가수-노래제목’ 형식으로\n입력해주세요!',
          confirm: '확인',
          useCancel: false,
        })
        return false;
      }
      return true;
    },
    checkValid() {
      let not = [];
      switch (this.formData.genre){
        case '노래': 
          not = ['genreType','showHost','mcStyle','program','bodyHeight','bodyWeight','bodyTopsize','bodyBottomsize','bodyShoesize'];
          break;
        case 'MC': 
          not = ['showHost','teamType','recommSong','matchVoice','program','bodyHeight','bodyWeight','bodyTopsize','bodyBottomsize','bodyShoesize'];
          break;
        case '쇼호스트': 
          not = ['genreType','subGenre','mcStyle','teamType','recommSong','matchVoice','program','bodyHeight','bodyWeight','bodyTopsize','bodyBottomsize','bodyShoesize'];
          break;
        case '모델': 
          not = ['genreType','subGenre','showHost','mcStyle','teamType','recommSong','matchVoice','program'];
          break;
        case '댄스': case '연주': case '퍼포먼스': 
          not = ['genreType','showHost','mcStyle','teamType','recommSong','matchVoice','program','bodyHeight','bodyWeight','bodyTopsize','bodyBottomsize','bodyShoesize'];
          break;
        default : 
          not = ['genreType','subGenre','showHost','mcStyle','teamType','recommSong','matchVoice','program','bodyHeight','bodyWeight','bodyTopsize','bodyBottomsize','bodyShoesize'];
          break;
      }
      let isValid = true
      for (let key in this.formData) {
        if(not.indexOf(key) < 0){
          if (key != 'agreeDate' && key != 'artistidx' && ( !this.formData[key] || this.formData[key] == '' )) {
            isValid = false
            break;
          }
        }
      }
      this.validate.isValid = isValid;
      this.$emit('validate', this.validate)
    },
    genreReset(){
      this.inputData.genreType =  this.genreTypes.reduce((result, row) => { return Object.assign(result, { [row]: false })}, {})
      this.inputData.showHost = this.labels.reduce((result, row, key) => { return Object.assign(result, { [key]: false })}, {})
      this.inputData.mcStyle = [];
      this.inputData.teamType = [];
      this.inputData.recommSong = [];
      this.inputData.matchVoice = [];
      this.etcData.subGenre = '';
      this.formData.subGenre = null;
      this.formData.genreType = null;
      this.formData.showHost = null;
      this.formData.mcStyle = null;
      this.formData.teamType = null;
      this.formData.recommSong = null;
      this.formData.matchVoice = null;
      this.$emit('update-data', this.formData);
      this.$store.commit("setEtcGenreText", []);
    },
    showTooltip(name) {
      this.tooltip[name] = true
      setTimeout(() => {
        this.tooltip[name] = false
      }, 2000)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

h4 {
  font-weight: 400;
  font-size: 2rem !important;
}
.guideLetter {
  color: #5a5a5a;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  b {
    font-weight: 500;
  }
}


#page > .container {
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-top: 0.4rem;
  margin-bottom: 9rem;
  .page-header {
    padding-bottom: 0;
  }
  h4 {
    margin-top: 0;
    font-weight: 400;
    font-size: 2.5rem !important;
    .essential {
      display: inline-block;
      margin-left: 0.2rem;
      color: #F33841;
    }
  }
  .my-profile-wrap {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 3rem;
    .form-wrap {
      margin-top: 3rem;
      margin-bottom: 6rem;
      & ~ .form-wrap {
        margin-top: 0;
      }
      .guideLetter {
        margin-bottom: 3rem;
        padding: 1.2rem 2rem;
        border-radius: 0.8rem;
        background-color: rgba(#AB54BB,0.1);
        font-size: 1.6rem;
        line-height: 2.1rem;
        color: #AB54BB;
      }
      h4 {
        & ~ .input-help {
          margin-top: -1.5rem;
          margin-bottom: 1.4rem;
          font-size: 1.9rem;
          color: #999;
        }
      }
      .ui-inputbox {
        padding: 0;
        &.typing-count {
          position: relative;
          input {
            padding-right: 7.5rem;
          }
          .counting {
            position: absolute;
            right: 3rem;
            top: 50%;
            color: #a8a8a8;
            font-size: 1.4rem;
            transform: translateY(-50%);
          }
        }
        &.has-btn {
          display: flex;
          align-items: center;
          gap: 1.2rem;
          .add-btn {
            flex-shrink: 0;
            padding: 0 3.2rem;
            height: 7.4rem;
            border-radius: 0.8rem;
            background-color: #AB54BB;
            font-size: 2rem;
            color: white;
            letter-spacing: -0.05rem;
          }
        }
        &.has-btn.typing-count {
          .counting {
            right: 13.6rem;
          }
        }
        input {
          padding: 2.2rem 3rem;
          border: 0.1rem solid #DDDDDD;
          border-radius: 0.8rem;
          line-height: 2.8rem;
          &::placeholder {
            color: #a8a8a8 !important;
          }
        }
      }
      .ui-check-box {
        padding: 2.2rem 3rem;
        border: 1px solid #DDDDDD;
        border-radius: 0.8rem;
        &.on {
          color: black;
        }
      }
      .ui-tag-box {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem 1rem;
        li {
          margin: 0;
        }
      }

      .added-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
        margin-top: 2.8rem;
        li {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          gap: 0.5rem;
          padding: 1.2rem 2.2rem;
          border-radius: 99.9rem;
          background-color: #F5F5F5;
          span {
            font-size: 1.8rem;
            color: #383838;
            line-height: 2.1rem;
          }
        }
      }
    }
  }
}

.profile-tooltip {
  position: relative;
  .profile-tooltip-toast {
    opacity: 0;
    position: absolute;
    top: -14.4rem;
    left: 0;
    right: 0;
    transition: 0.3s;
    z-index: -1;
    line-height: normal;
    &.show {
        opacity: 1;
        z-index: 10;
    }
  }
  .profile-tooltip-btn {
    display: inline-block;
    margin-left: 0.8rem;
    vertical-align: middle;
  }
  // .profile-tooltip-btn:hover ~ .profile-tooltip-toast {
  //   opacity: 1;
  // }
}
</style>
