var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("progress-bar", { attrs: { percent: _vm.formData.percent } }),
      _c("nav-top", {
        attrs: { list: _vm.nav.list, "current-key": _vm.nav.currentKey }
      }),
      _c("Default", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.nav.currentKey == 0,
            expression: "nav.currentKey == 0"
          }
        ],
        attrs: { artistData: _vm.formData },
        on: {
          "update-data": _vm.updateArtistData,
          validate: _vm.handleValidate
        }
      }),
      _c("Detail", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.nav.currentKey == 1,
            expression: "nav.currentKey == 1"
          }
        ],
        attrs: { artistData: _vm.formData },
        on: {
          "update-data": _vm.updateArtistData,
          validate: _vm.handleValidate
        }
      }),
      _c("Active", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.nav.currentKey == 2,
            expression: "nav.currentKey == 2"
          }
        ],
        attrs: { secondTab: _vm.secondTabs, artistData: _vm.formData },
        on: {
          "update-data": _vm.updateArtistData,
          validate: _vm.handleValidate
        }
      }),
      _c("footer-box", {
        attrs: {
          single: false,
          submitText: "다음",
          submitCb: function() {
            return _vm.nextStep()
          },
          submitDisabled: !_vm.validate,
          btnText: "미리보기",
          btnCb: function() {
            return _vm.preview()
          },
          "small-btn": ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }